
import { API } from "../config/api";

export default class OptionsService  {

    // Get the list of all the dropdown options ====================================================================================================

    /**
     * @description Gets the list of all the dropdown options
     */

     static getDropDownOptions() {
        const url = `/options/colletionAll`;

        return API.get(url);
    }


    // Get the list of states by country ====================================================================================================

    /**
     * @param country the id of the country
     * @description Gets the list of states by country
     */

    static getStatesByCountry(country) {
        const url = `/options/states?country_id=${country}`;

        return API.get(url);
    }

    
    // Get the list of cities by state ====================================================================================================

    /**
     * @param state the id of the state
     * @description Gets the list of cities by state
     */

    static getCitiesByState(state) {
        const url = `/options/cities?state_id=${state}`;

        return API.get(url);
    }

     
    // Get the list of cities by country ====================================================================================================

    /**
     * @param county the id of the county
     * @description Gets the list of cities by county
     */

    static getCitiesByCountry(county) {
        const url = `/options/cities?country_id=${county}`;

        return API.get(url);
    }

    
    // Get the list of municipalities by city ====================================================================================================

    /**
     * @param city the id of the city
     * @description Gets the list of municipalities by city
     */

    static getMunicipalitiesByCity(city) {
        const url = `/options/municipalities?city_id=${city}`;

        return API.get(url);
    }


    // Get the list of parishies by municipality ====================================================================================================

    /**
     * @param municipality the id of the municipality
     * @description Gets the list of parishies by municipality
     */

    static getParishiesByMunicipality(municipality) {
        const url = `/options/parishies?municipality_id=${municipality}`;

        return API.get(url);
    }


    // Get the list of sectors by state ====================================================================================================

    /**
     * @param state the id of the state
     * @description Gets the list of sectors by state
     */

    static getSectorsByState(state) {
        const url = `/options/sectors?state_id=${state}`;

        return API.get(url);
    }

}
