import React from 'react';
import { Link } from 'react-router-dom';

// Styles

import styles from './Header.module.scss';

// Images

import logo from './Images/img-brand-logo.png';
import icnHome from './Images/icn-home.png';
import icnLogOut from './Images/icn-logout.png';

export default function Header() {
  return (
    <div className={styles.header}>
        <div className='container'>
            <div className={styles.wrapper}>
                <div>
                    <h1>
                        <img src={logo} alt="Bancaribe" className={styles.brand} />
                    </h1>

                    <Link to={'/'} className={styles.btnHome}>
                        <img src={icnHome} alt="Inicio" />
                        <span>Inicio</span>
                    </Link>
                </div>

                <div>
                    <p className={styles.date}><em>Última conexión <strong>15/05/2022 | 12:47:00</strong></em></p>

                    <button className={styles.btnLogOut} href="">
                        <img src={icnLogOut} alt="Cerrar Sesión" />
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
