import React from 'react'
import { Dropdown } from 'primereact/dropdown';

import InputWrapper from './InputWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import dropDownIcn from './Images/icn-dropdown.png';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function DropDownInput(props) {
    const {
        formik,
        fieldName,
        isRequired,
        options,
        placeholder,
        additionalFunctions,
        disabled,
        loading,
    } = props;

    return (
        <InputWrapper {...props}>
            <Dropdown
                name={fieldName}
                className={`input-field ${formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}`}
                value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                options={options}
                onChange={(e) => { 
                    formik.setFieldValue(fieldName, e.target.value); 
                    if (additionalFunctions) { 
                        additionalFunctions() 
                    }
                }}
                onBlur={formik.handleBlur(fieldName)}
                dropdownIcon={''}
                required={isRequired}
                placeholder={placeholder ? placeholder : 'Seleccione'}
                disabled={disabled}
                // filter
            />

            {loading ? (
                <FontAwesomeIcon className='dropdown-icon drowpdown-loading-icon' icon={faCircleNotch} spin />
            ) : (
                <img src={dropDownIcn} className='dropdown-icon' alt={fieldName} />
            )}

        </InputWrapper>
    )
}

