import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

const icons = {
  add: faPlus,
  delete: faTrash,
}

export default function ButtonWithIcon({label, action, disabled, isLoading, icon}) {
  return (
    <button className={icon === 'delete' ? "btn-with-icon btn-with-icon-danger" : "btn-with-icon"}
      type='button'
      onClick={() => action()}
      disabled={disabled}
    >
        {!isLoading ? (
            <>
              <FontAwesomeIcon icon={icon ? icons[icon] : icons.add} className='btn-with-icon-icon' />
              {label}
            </>
        ) : (
            <FontAwesomeIcon icon={faSpinner} spin />
        )}
    </button>
  )
}
