import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  values: null,
  showModuleIntro: true,
  module: 0,
  formStep: 0,
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {

    setFormValues: (state, action) => {
      state.values = action.payload;
    },

    setShowModuleIntro: (state, action) => {
      state.showModuleIntro = action.payload;
    },

    setModule: (state, action) => {
      state.module = action.payload;
    },

    setFormStep: (state, action) => {
      state.formStep = action.payload;
    },

    resetFormSteps: () => initialState,
  },
})

export const { 
  setFormValues, setShowModuleIntro, setModule, setFormStep, resetFormSteps,
} = formsSlice.actions

export default formsSlice.reducer;