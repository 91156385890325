import React from 'react'

// Styles

import './Styles.scss';

export default function FormWrapper({children}) {
  return (
    <div className='page-wrapper'>
      <div className='container'>
        <div className="form-wrapper">

          {children}

        </div>
      </div>
    </div>
  )
}
