import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  dropDownOptions: {},
};

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {

    // Get the list of all the dropdown options

    setgetDropDownOptionsLoading: (state) => {
      state.dropDownOptions = {
        isLoading: true,
      };
    },
    setgetDropDownOptionsScuccess: (state, action) => {
      state.dropDownOptions = {
        data: action.payload
      };
    },
    setgetDropDownOptionsFailure: (state, action) => {
      state.dropDownOptions = {
        error: action.payload,
      }
    },
  },
})

export const { 
  setgetDropDownOptionsLoading, setgetDropDownOptionsScuccess, setgetDropDownOptionsFailure,
} = optionsSlice.actions

export default optionsSlice.reducer;