import * as yup from 'yup';

export const validationSchema = [
    yup.object().shape({
      name: yup
        .string()
        .required('Favor ingresar tus nombres'),
      firstlastname: yup
        .string()
        .required('Favor ingresar tus apellidos')
        // .min(11, 'Verifíca que estes ingresando el número correcto'),
    }),
    yup.object().shape({
      field1: yup
        .string()
        // .required('Favor selecionar tu nivel de estudios'),
    }),
    yup.object().shape({
      field2: yup
        .string()
        // .required('Favor selecionar el tipo de vivienda'),
    })
];