import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

// Utilities

import { truncateString } from '../../../utilities/helper-functions';

import './Styles.scss';

const fileTypes = {
    image: "image/x-png,image/jpeg",
    excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export default function FileInput(props) {
    const {
        formik,
        fieldName,
        label,
        isRequired,
        fileType,
    } = props;

    const fileUpload = useRef(null);

    return (
        <div className="file-field">
            {label &&
            <label>{label} {isRequired && <span>*</span>}</label>}

            <button
                type="button"
                className={formik.touched[fieldName] && formik.errors[fieldName] ? "file-input-button input-error" : "file-input-button"}
                onClick={() => fileUpload.current.click()}
            >
                <FontAwesomeIcon icon={faPaperclip} /> {formik.values[fieldName] ? truncateString(formik.values[fieldName].name, 30) : 'Seleccionar archivo'}
            </button>

            <button className='file-input-help-button'>
                <FontAwesomeIcon icon={faCircleQuestion} />
            </button>

            <input
                ref={fileUpload}
                className="file-input"
                id={fieldName}
                name={fieldName}
                type="file" 
                accept={fileType ? fileTypes[fileType] : fileTypes.image}
                onChange={(event) => { formik.setFieldValue(fieldName, event.currentTarget.files[0]) }}
                onBlur={formik.handleBlur(fieldName)}
                required={isRequired}
            />

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
