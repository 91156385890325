// Form initial values

export const initialValues = {
    date: '',
    number: '',
    dropdown: '',
    productCurrency: [],
    fundsBolivares: {},
    fundsDolares: {},
    fundsEuros: {},
    fundsPesosColombianos: {},
    fundsMonedaVirtual: {},
};