// Form initial values

export const initialValues = {
    date: '',
    number: '',
    dropdown: '',
    hasBankReference: '',
    bankReferences: [
        {
            refdate: '',
            refbank:'',
            refaccounttype: '',
            refaccountnumber: '',
            refopendate: '',
            refaccountaverage: '',
            refaccountcalification: '',
            refaobservation: ''
        }
    ],
    personalReferences: [
        {
            documentIdType: '',
            documentId: '',
            names:'',
            firstLastName: '',
            secondLastName: '',
            relationship: '',
            phoneType1: '',
            phoneAreaCode1: '',
            phoneNumber1: '',
            phoneType2: '',
            phoneAreaCode2: '',
            phoneNumber2: '',
        }
    ]
};