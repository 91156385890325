import React, {useState, useEffect} from 'react'

// Hooks

import GetCitiesByCountry from '../../../../hooks/GetCitiesByCountry';

// Components

import DropDownInput from '../../../UI/FormInput/DropDownInput';

// Utilities

import { formatDropDownOptions } from '../../../../utilities/helper-functions';

export default function JobProviderAddressFields({dropDownOptions, formik, index, i}) {
    const { handleGetCitiesByCountry } = GetCitiesByCountry();
    // Cities
    const [getCitiesIsLoading, setGetCitiesIsLoading] = useState(false);
    const [getCitiesError, setGetStatesAndCitiesError] = useState(null);
    const [cities, setCities] = useState([]);

    // Get Cities by Country

    useEffect(() => {
        if (formik.values.jobReferences[index].businessSuppliers[i].country) {
            handleGetCitiesByCountry(
                formik.values.jobReferences[index].businessSuppliers[i].country,
                setGetCitiesIsLoading,
                setGetStatesAndCitiesError,
                setCities
            );
        }
    }, [handleGetCitiesByCountry, formik.values.jobReferences[index].businessSuppliers[i].country])

    return (
        <>
            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.businessSuppliers.${i}.country`}
                label={'País'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.businessSuppliers.${i}.city`}
                label={'Ciudad'}
                options={formatDropDownOptions(cities)}
                isRequired={true}
                disabled={cities?.length === 0 || getCitiesIsLoading || getCitiesError}
                loading={getCitiesIsLoading}
            />

        </>
    )
}