import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';

// Styles

import './Styles.scss';

export default function DownloadDocumentButton({label, previewAction, downloaDAction}) {
  return (
    <div className='btn-download-document'>
        <p>{label}</p>
        <button  onClick={() => previewAction()} title={`Vista previa ${label}`}>
            <FontAwesomeIcon icon={faEye} className='btn-with-icon-icon' />
        </button>
        <button onClick={() => downloaDAction()} title={`Descargar ${label}`}>
            <FontAwesomeIcon icon={faDownload} className='btn-with-icon-icon' />
        </button>
    </div>
  )
}
