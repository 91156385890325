import React, {useState} from 'react'

// Components

import WelcomeCard from '../../components/cards/WelcomeCard/WelcomeCard';
import RegisterForm from '../../components/forms/RegisterForm/RegisterForm';
import LogInForm from '../../components/forms/LogInForm/LogInForm';
import AccessCodeForm from '../../components/forms/AccessCodeForm/AccessCodeForm';

// Styles

import './Styles.scss';

export default function UserAccess() {
  const [showForm, setShowForm] = useState('register');
  const [userName, setUsername] = useState();
  const [userEmail, setUserEmail] = useState();
  return (
    <div className="page-wrapper">
      <div className="container">
        <div className='user-access'>
          
          <WelcomeCard />

          <div className='user-access-forms'>
            {showForm === 'register' &&
            <RegisterForm setShowForm={setShowForm} setUsername={setUsername} setUserEmail={setUserEmail} />}

            {showForm === 'login' &&
            <LogInForm setShowForm={setShowForm} />}

            {showForm === 'code' &&
            <AccessCodeForm setShowForm={setShowForm} userName={userName} userEmail={userEmail} />}
          </div>
        </div>
      </div>
    </div>
  )
}
