import React from 'react'
import { Checkbox } from 'primereact/checkbox';

import InputWrapper from './InputWrapper';

import './Styles.scss';

export default function CheckBoxInput(props) {
    const {
        formik,
        fieldName,
        options,
        additionalFunctions,
    } = props;

    const onValueChange = (e) => {
        let selectedValues = [...formik.values[fieldName]];
        if (e.checked) {
           selectedValues.push(e.value);
        } else {
            const index = selectedValues.indexOf(e.value);
            selectedValues.splice(index, 1);
        }
        
        formik.setFieldValue(fieldName, selectedValues);
    }

    return (
        <InputWrapper {...props}>

            <div className="checkbox-input-wrapper">

                {options.map((option, i) =>
                    <span key={i} className='check-input'>
                        <Checkbox
                            key={i}
                            name={fieldName}
                            inputId={option.value}
                            value={option.value}
                            checked={formik.values[fieldName]?.includes(option.value)}
                            onChange={(e) => { onValueChange(e); if (additionalFunctions) { additionalFunctions() } }}
                            onBlur={formik.handleBlur(fieldName)}
                            label={option.label}
                        />
                        <label htmlFor={option.label}>
                            {option.label}
                        </label>
                    </span>
                )}

            </div>

        </InputWrapper>
    )
}

