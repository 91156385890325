import React from 'react'

import { useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import Button from '../../../../UI/Button/Button';
import ButtonIconOutline from '../../../../UI/Button/ButtonIconOutline';

export default function TermsAndConditions({formik, formStep, setFormStep, isSubmitting}) {
    const dispatch = useDispatch();

    return (
    <>
        <SectionHeading text={'Términos y Condiciones'} />

        <div className='inline-text-box'>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam orci enim, convallis ut dictum vel, mollis in eros. Quisque pellentesque libero ac lacus sollicitudin, a lacinia diam lobortis. Aliquam erat volutpat. Aenean pharetra euismod sem, nec sollicitudin sem varius mattis. Suspendisse vulputate sed nulla a malesuada. Nam eget velit est. Sed ultrices at eros sodales convallis. Nunc eleifend libero vel mauris auctor pretium. Mauris vulputate dapibus sollicitudin.</p>

            <p>Maecenas non aliquet lacus. Cras turpis enim, mattis in auctor non, consectetur quis justo. Aenean faucibus ultrices maximus. Aenean ultrices iaculis viverra. Fusce porttitor aliquet est in interdum. Praesent quis sapien placerat, ultrices enim eget, malesuada magna. Suspendisse neque nisl, posuere ut odio sit amet, iaculis mollis felis. Duis a placerat magna. Sed ultrices non risus nec iaculis. Cras tincidunt eget justo id laoreet.</p>

            <p>Pellentesque ultricies accumsan faucibus. Etiam vel commodo risus, a ultricies mauris. Sed nulla diam, molestie nec urna a, ultricies lobortis felis. Donec lobortis, sem ut porta accumsan, est metus imperdiet purus, sed accumsan eros tellus quis augue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed eget ligula sed tellus ultricies finibus vel at est. Integer a facilisis purus. Aenean arcu mauris, viverra in nunc suscipit, scelerisque hendrerit dolor. Donec pellentesque sem sit amet dui tristique, nec fermentum nulla accumsan. Ut malesuada orci turpis, blandit scelerisque felis luctus scelerisque. Nam sit amet sapien maximus ipsum posuere lacinia malesuada eu erat. Nunc feugiat interdum lacus, id tempor libero ultrices ac. Ut maximus sem eget molestie tristique. Maecenas venenatis, nulla et tincidunt aliquam, neque felis pellentesque purus, vel sodales magna dui sit amet felis.</p>

            <p>Maecenas dictum mi sit amet vestibulum porta. Phasellus a lorem non dolor commodo rhoncus. Nunc libero erat, mattis eget elementum quis, viverra vitae dui. Nunc eget pulvinar mi. Cras vulputate diam vel mauris pulvinar tincidunt. Aliquam eget posuere nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>

            <p>Mauris maximus euismod risus. Fusce eu risus iaculis, consectetur ex a, feugiat augue. Phasellus nisi lectus, tempor vel lectus sed, ultrices porttitor sapien. Ut nunc tellus, consectetur in mauris vel, mattis convallis mi. Quisque sed iaculis augue. Aenean vulputate lacus eget porttitor pharetra. Aenean sit amet erat congue, commodo mauris placerat, facilisis quam. Vestibulum ante nulla, sodales id nisi eget, sodales suscipit nunc. Curabitur tincidunt ullamcorper luctus. Donec id consequat nibh. Morbi ligula metus, congue sit amet tempor vitae, auctor non sem. Mauris interdum, massa a vehicula facilisis, sem lectus molestie nunc, sollicitudin maximus arcu diam quis elit. Donec congue euismod nisi, non bibendum metus vehicula volutpat. Curabitur sed sodales ex, eget efficitur est. Integer suscipit lacus eu velit ultrices eleifend.</p>

            <p>Curabitur ornare nibh ut justo semper, a molestie magna vulputate. Integer pretium tortor urna, sit amet mattis felis faucibus sed. Duis in tristique nulla, in congue metus. Nulla convallis tristique egestas. Aliquam ante lacus, dapibus ac ornare quis, facilisis a ante. Proin lobortis, urna vel consequat fermentum, lectus lectus suscipit ligula, eget varius felis ex vel libero. Nam sed eros at dui feugiat mattis eu vitae arcu. Donec ac nunc eu lorem imperdiet egestas eget quis turpis. Cras a libero sapien.</p>

            <p>Vestibulum tristique condimentum est, non mattis libero tempor in. Curabitur accumsan malesuada turpis ut rhoncus. Integer a risus non nisi vulputate tincidunt sit amet volutpat lectus. Fusce imperdiet dolor in neque auctor, ultricies bibendum augue sodales. Morbi hendrerit est molestie sapien mattis feugiat. Donec ac condimentum sapien. Donec scelerisque, sapien id hendrerit sodales, nibh ipsum suscipit mauris, ut vestibulum ex elit ac mauris. Sed et elit nec massa porttitor consequat vel eu nibh. Nam iaculis, ex nec efficitur viverra, quam ante convallis felis, ut fringilla lacus purus eu metus. Nulla non libero ex. Sed consequat ullamcorper sem sit amet bibendum. Aliquam a erat luctus, tempor ex sed, pulvinar nulla. Aliquam congue, purus non aliquet vestibulum, eros nibh imperdiet felis, interdum sollicitudin nunc libero quis purus. Cras lorem nunc, blandit quis fermentum eu, dictum vitae felis. Cras non nibh eget erat fermentum efficitur.</p>

            <p>Sed dictum orci ac tellus fermentum porta. Morbi tincidunt, nisl ut laoreet tempus, leo metus laoreet turpis, id tincidunt ipsum lacus nec urna. Morbi facilisis nisl eget nibh bibendum ornare. Cras consectetur nisl vitae tortor tempus vulputate. Nam pellentesque venenatis est sit amet tempor. Maecenas non nulla orci. Nulla bibendum enim dictum orci cursus porttitor. Donec ipsum massa, posuere ac mollis ut, cursus nec velit. Sed in ante ex. Nunc id arcu vel ligula porttitor iaculis. Ut varius augue vel nulla dapibus rhoncus. Quisque lacinia ligula sed odio suscipit, at dictum justo venenatis. Phasellus feugiat imperdiet ex, mattis blandit ligula. Suspendisse nec pulvinar mauris. Morbi blandit enim tortor, sed gravida eros suscipit eu. In nec eleifend est.</p>

            <p>Cras egestas volutpat justo ac elementum. Suspendisse molestie sem et ante gravida auctor. Fusce lobortis id quam eu scelerisque. Suspendisse a libero egestas purus molestie efficitur. Duis dignissim ac enim sit amet malesuada. Aenean consectetur nibh turpis, vitae ornare lacus pellentesque ut. Sed eu ligula rhoncus, molestie nulla vel, sagittis lacus. Vivamus dignissim id lacus vel egestas.</p>

            <p>Aliquam erat volutpat. Sed quis ultrices dui. Nullam et tempus quam, in auctor ipsum. Mauris ac suscipit turpis. Aliquam sodales dui quis purus finibus pulvinar. Nam id velit orci. Etiam felis felis, hendrerit nec nibh eget, efficitur suscipit purus. In cursus, nulla at ornare viverra, urna massa fermentum nulla, eu viverra purus dui id augue. Duis non efficitur odio, sit amet luctus ex. Suspendisse tempus euismod sodales.</p>

            <p>Sed eget iaculis sapien, ut consequat ex. Nullam congue, mi mattis tempus sodales, sapien lectus euismod arcu, euismod tempus metus mi vel quam. Sed finibus porta mi, in lobortis tellus pharetra non. Ut eu elit mattis, tempus nisl tincidunt, sodales ligula. Vivamus ac purus massa. Nam sit amet ullamcorper risus. Pellentesque dapibus blandit ipsum varius aliquet. Cras consequat cursus porta. Donec bibendum tellus tellus, nec hendrerit lorem vehicula ac. Ut tempor erat varius laoreet consequat. Aliquam erat volutpat. Aliquam erat volutpat. Maecenas et commodo arcu.</p>

            <p>Suspendisse et nisi fringilla, accumsan ipsum sit amet, mattis lorem. Proin in odio pretium, efficitur quam et, venenatis magna. Suspendisse gravida dolor a ligula gravida vulputate. Vivamus fringilla a ligula sit amet iaculis. Curabitur porttitor dui arcu, ut sollicitudin leo ultrices id. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec hendrerit nulla et ex dignissim, sit amet feugiat ante dignissim. Sed semper ipsum non nisl consectetur aliquet. Fusce gravida, neque vel molestie congue, mi neque lacinia tellus, a mattis tellus neque et ante. Pellentesque at sem quis enim semper feugiat nec in mauris. Integer placerat sed urna sed efficitur. Cras blandit ante vitae lorem blandit venenatis. In sodales malesuada lacinia. Donec sit amet ipsum viverra, volutpat turpis ut, luctus augue.</p>

            <p>Praesent suscipit pellentesque scelerisque. Donec tempor ac sapien quis eleifend. Proin iaculis mauris a purus auctor, sed congue metus fermentum. Quisque vel viverra leo, ac dapibus lorem. Fusce id molestie tellus, ut gravida nisi. Phasellus gravida ultrices nulla, vel condimentum massa vulputate lacinia. Aenean consequat diam eget ante accumsan, non ornare dui imperdiet. Mauris a tortor fringilla sem venenatis rutrum. Aenean volutpat quis augue ac feugiat. Etiam molestie metus quis accumsan fermentum. Etiam facilisis ante pellentesque tellus tincidunt dictum. Vestibulum sed elit non ligula eleifend dignissim.</p>

            <p>Maecenas in hendrerit dolor. Ut hendrerit sodales ex vitae tincidunt. Proin condimentum ex tortor, at iaculis nisl interdum convallis. Nulla ac malesuada neque, nec blandit arcu. Etiam ac turpis id ipsum pellentesque pulvinar. Donec gravida rutrum leo in convallis. Proin sagittis sodales accumsan. Donec eu efficitur nibh. Praesent mauris arcu, vestibulum vitae ligula ullamcorper, tristique lobortis ex. Quisque sollicitudin, neque ac pretium vehicula, lectus enim consectetur dui, vel volutpat ex ipsum a ante. Morbi nec mattis lorem.</p>

            <p>Proin urna nulla, sagittis eu rhoncus et, volutpat ut tortor. Vivamus at purus lacus. Duis vitae risus nulla. Nunc placerat scelerisque aliquam. Maecenas faucibus diam sed facilisis porta. Mauris in magna venenatis, mollis nunc ut, tristique mi. Nunc ut sem a eros volutpat sodales. In non tempus leo, eget scelerisque ex. Integer bibendum quis nunc consectetur facilisis. Nulla dictum lorem in mollis placerat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In mollis pellentesque sollicitudin. Morbi facilisis dictum ultrices. Integer pharetra, erat vitae pretium porta, leo nibh imperdiet eros, vel euismod nulla elit sit amet dui. Sed nec placerat velit. Duis blandit urna sed nunc hendrerit auctor.</p>

            <p>Quisque at sapien massa. Duis eget elit maximus, commodo purus nec, congue eros. Aliquam fermentum ante ipsum, at porttitor sapien semper scelerisque. Nam sagittis massa ac sapien blandit, eget rutrum ex maximus. Morbi efficitur non purus sit amet consectetur. Pellentesque rhoncus iaculis velit ut gravida. In id suscipit tortor, non laoreet libero. Nulla tincidunt lectus leo, at luctus nunc ornare vitae. Curabitur in vestibulum augue. Mauris non placerat dui. In cursus, erat vitae hendrerit dapibus, orci eros semper lectus, blandit mollis massa sem eget diam. Fusce et urna at risus mollis malesuada sollicitudin sed turpis. Fusce eget tincidunt urna, nec ornare ipsum.</p>

            <p>Integer in aliquet purus, ut cursus sem. Nam ac maximus felis. Vestibulum sed porta sem, in maximus arcu. Vivamus felis urna, finibus a odio sed, ultrices pellentesque est. Aliquam eu viverra dolor. Praesent gravida, purus id vulputate euismod, justo orci tempor nisl, vitae porttitor justo velit nec ante. Etiam risus nisl, mattis nec dolor ut, porta mollis felis. In hac habitasse platea dictumst. Nam eget sodales eros. Quisque eu ipsum orci. Praesent et condimentum tortor. Sed cursus cursus magna et egestas.</p>

            <p>Pellentesque sit amet lacus vel lorem viverra faucibus. Fusce sit amet porttitor tellus, ac malesuada purus. Pellentesque interdum, felis id placerat tempus, purus elit rutrum tellus, non commodo felis tortor ac turpis. Suspendisse tincidunt semper porta. Maecenas pretium massa ut est placerat, tempor dapibus arcu bibendum. Donec vulputate viverra feugiat. Fusce scelerisque purus vitae sapien egestas, at imperdiet libero sollicitudin. Maecenas vitae neque gravida, tincidunt ex sit amet, rhoncus lectus. Ut ut commodo tortor. Proin et libero at enim venenatis sagittis. Cras ut luctus eros. In vel auctor libero. Cras at purus malesuada dolor ultrices faucibus. Vestibulum viverra ac mauris vitae consequat. In hac habitasse platea dictumst. Maecenas quis dapibus sem.</p>

            <p>Vestibulum blandit tincidunt ligula, ut consectetur elit tristique iaculis. Nam cursus hendrerit sapien nec venenatis. Proin interdum laoreet quam et ullamcorper. Nulla commodo aliquam tellus id posuere. Suspendisse potenti. Integer sodales mollis euismod. Donec mattis fermentum arcu, at pretium purus sodales tristique. Pellentesque hendrerit leo vitae nisl efficitur auctor.</p>

            <p>In magna velit, euismod porttitor eros ac, elementum cursus nibh. Praesent accumsan elementum risus ut molestie. Duis volutpat, elit sed euismod euismod, neque felis lacinia nunc, at dignissim nulla ex eu neque. Nam suscipit est at nibh ultrices, eu porta lorem rutrum. Nunc turpis magna, tincidunt ut ultrices vel, ornare eu ligula. Vivamus ex magna, pharetra vel tristique et, condimentum in leo. In dolor nibh, aliquet eget bibendum ac, dapibus vel felis. Morbi tempus risus nulla, ut rhoncus dui sodales at. Donec eget diam mattis, imperdiet ex quis, porttitor dui.</p>

        </div>

        <RadioInput
            formik={formik}
            fieldName={'terms'}
            label={''}
            options={[{label: 'Sí, he leído y entiendo los términos y condiciones *', value: 'si'}]}
            isGroup={true}
            // isRequired={true}
        />

        <div className='form-actions'>

            <Button
                action={() => dispatch(setFormStep(formStep - 1))}
                label={'Regresa'}
                disabled={isSubmitting}
                secondaryStyle={true}
            />

            <ButtonIconOutline
                action={() => null}
                label={'Descargar'}
                disabled={isSubmitting}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                isLoading={isSubmitting}
            />

        </div>
    </>
  )
}
