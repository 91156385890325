import React from 'react'
import { locale, addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

import InputWrapper from './InputWrapper';

import './Styles.scss';

locale('es');

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Llunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
});

export default function CalendarInput(props) {
    const {
        formik,
        fieldName,
        isRequired,
        placeholder,
        disabled,
    } = props;

    return (
        <InputWrapper {...props}>

            <Calendar
                id={fieldName}
                name={fieldName}
                dateFormat="dd/mm/yy"
                locale="es"
                value={formik.values[fieldName] ? moment(formik.values[fieldName]).toDate() : ''}
                // onChange={formik.handleChange(fieldName)}
                onChange={(e) => formik.setFieldValue(fieldName, moment(e.value).format('YYYY-MM-DD'))}
                onBlur={formik.handleBlur(fieldName)}
                placeholder={placeholder}
                className={`input-field ${formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}`}
                required={isRequired}
                disabled={disabled}
            />

        </InputWrapper>
    )
}

