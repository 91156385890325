// Form initial values

export const initialValues = {
    date: '',
    number: '',
    dropdown: '',
    hasJob: '',
    jobReferences: [
        {
            sameAddress: '',
            country: '',
            state:'',
            city: '',
            municipalty: '',
            church: '',
            urban: '',
            street: '',
            inmueble: '',
            housename: '',
            housedesc: '',
            housefloor: '',
            apartment: '',
            phoneType: '',
            phoneAreaCode: '',
            phone: '',
            constancy: '',
            doctype: '',
            rif: '',
            constancydate: '',
            salary: '',
            contracttype: '',
            company: '',
            activity: '',
            jobdate: '',
            jobrole: '',
            jobcurency: '',
            jobemail: '',
            jobbranch: '',
            businessSuppliers: [
                {
                    name: '',
                    country: '',
                    RIF: '',
                    location: '',
                }
            ],
            businessClients: [
                {
                    name: '',
                    country: '',
                    RIF: '',
                    incomeDeclaration: '',
                    virtualCurrency: '',
                }
            ]
        }
    ]
};