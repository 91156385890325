import React, { useState } from 'react';

import { useFormik } from 'formik';

// Hooks

// import GetAuthToken from '../../../hooks/GetAuthToken';
import CreateEntry from '../../../hooks/CreateEntry';

// Components

import SectionHeading from '../../UI/SectionHeading/SectionHeading';
import TextInput from '../../UI/FormInput/TextInput';
import Button from '../../UI/Button/Button';

// Validation

import { initialValues } from './InitialValues';
import { validationSchema } from './ValidationSchema';

export default function LogInForm({setShowForm}) {
  const { isSubmitting, submitError, createNewEntry } = CreateEntry();

  // Setup form with formik

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      sendFormRequest(values, actions);
    },
  });


  // Send Form Request

  const sendFormRequest = async (values, actions) => {
    // createNewEntry(values);
    setShowForm('code')
  }

  return (  
      <form onSubmit={formik.handleSubmit}>

        {/* Form Error Message */}

        {submitError && <p className="form-error-message">{submitError}</p>}

        {/* Form Fields */}

        <SectionHeading text={'Ingresa'} headingAlt={true} />

        <p className='form-paragraph'>Utiliza tu usuario y contraseña de Mi Conexión Bancaribe.</p>

        <TextInput
            formik={formik}
            fieldName={'user'}
            label={'Usuario'}
            maxLength={80}
            isRequired={true}
        />

        <TextInput
            formik={formik}
            fieldName={'password'}
            label={'Contraseña'}
            isRequired={true}
        />

        <div className='form-actions'>

          <Button
              type="submit"
              action={formik.handleSubmit}
              label={'Iniciar sesión'}
              additionalStyles={{width: '100%', margin: 0}}
              disabled={!(formik.isValid && formik.dirty)}
              // isLoading={state?.isLoading}
          />

          <button
            onClick={()=> setShowForm('register')}
            type='button'
            className='btn-link'
          >
            ¿No eres cliente? <strong>Regístrate</strong>
          </button>

        </div>

      </form>
  )
}