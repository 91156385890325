import React from 'react'

// Module Steps

import ProductCurrencies from './Steps/1.ProductCurrencies';
import TermsAndConditions from './Steps/2.TermsAndConditions';

export default function ProductInfo({formik, formStep, setFormStep, goToPreviousModule, isSubmitting}) {
  return (
    <>
        {/* STEP 1: Product Currencies */}

        {formStep === 0 &&
        <ProductCurrencies
          formik={formik}
          goToPreviousModule={goToPreviousModule}
        />}

        {/* STEP 2: Terms and Conditions */}

        {formStep === 1 &&
        <TermsAndConditions
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
          isSubmitting={isSubmitting}
        />}

    </>
  )
}
