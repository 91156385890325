// API
import VUService from '../../services/vu.service';

import { 
  setSendOtpLoading, setSendOtpSuccess, setSendOtpFailure, cleanupSendOtp,
  setValidateOtpLoading, setValidateOtpSuccess, setValidateOtpFailure, cleanupValidateOtp,
  setGetDocumentInfoLoading, setGetDocumentInfoSuccess, setGetDocumentInfoFailure, cleanupGetDocumentInfo,
} from './vu.reducers';


// VUAS Send OTP ====================================================================================================

/**
 * @param data {
        @string username,
        @string email,
    }
* @returns True or false if the email with the otp was sent
*/

export const vuasSendOtp = (data) => async (dispatch) => {
  dispatch(setSendOtpLoading());

  try {

    const response = await VUService.vuasSendOtp(data);

    if (response?.data?.success) {
      dispatch(setSendOtpSuccess(response?.data?.success));
    } else {
      dispatch(setSendOtpFailure(response?.data?.message));
    }

  } catch(error) {
    dispatch(setSendOtpFailure(error.response?.data?.message || error.message));
  }

}

// VUAS Validate OTP ====================================================================================================

/**
 * @param data {
        @string username,
        @string otp,
    }
* @returns True or false if the otp was valid
*/

export const vuasValidateOtp = (data) => async (dispatch) => {
  dispatch(setValidateOtpLoading());

  try {

    const response = await VUService.vuasValidateOtp(data);

    dispatch(setValidateOtpSuccess(response.data));

  } catch(error) {
    dispatch(setValidateOtpFailure(error.response?.data?.message || error.message));
  }

}

// Clean VUAS Store ====================================================================================================

/**
 * @description Cleans up the VUAS store
 */

export const cleanVuasStore = () => (dispatch, getState) => {
  dispatch(cleanupSendOtp())
  dispatch(cleanupValidateOtp())
};


// Get Document Info ====================================================================================================

/**
 * @param data {
        @string operationId,
        @string operationGuid,
        @string userName,
    }
* @returns The document info from the operation
*/

export const getDocumentInfo = (data) => async (dispatch) => {
  dispatch(setGetDocumentInfoLoading());

  try {

    const response = await VUService.getDocumentInfo(data);

    dispatch(setGetDocumentInfoSuccess(response.data));

  } catch(error) {
    dispatch(setGetDocumentInfoFailure(error.response?.data?.message || error.message));
  }

}

// Clean VU Get Document Info Store ====================================================================================================

/**
 * @description Cleans up the VU Get Document Info store
 */

export const cleanGetDocumentInfoStore = () => (dispatch, getState) => {
  dispatch(cleanupGetDocumentInfo())
};