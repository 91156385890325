import React from 'react'

import { useSelector } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import TextInput from '../../../../UI/FormInput/TextInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import MaskInput from '../../../../UI/FormInput/MaskInput';
import CurrencyInput from '../../../../UI/FormInput/CurrencyInput';
import CalendarInput from '../../../../UI/FormInput/CalendarInput';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import FileInput from '../../../../UI/FormInput/FileInput';
import NumberInput from '../../../../UI/FormInput/NumberInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';
import ButtonWithIcon from '../../../../UI/ButtonWithIcon/ButtonWithIcon';
import JobLocaleTypeFields from '../JobLocaleTypeFields';
import JobAddressFields from '../JobAddressFields';
import JobProviderAddressFields from '../JobProviderAddressFields';

// Utilities

import { addItemToArray, removeItemFromArray, formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { BUSINESS_PHONE_MASK_FORMAT, RIF_MASK_FORMAT, PHONE_MASK_FORMAT } from '../../../../../config/constants';

import { initialValues } from '../InitialValues';

export default function JobInformation({formik, goToPreviousModule}) {
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);

    return (
    <>
       <SectionHeading text={'Información Laboral'} />

        <RadioInput
            formik={formik}
            fieldName={'hasJob'}
            label={'¿Posee Empleo?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values?.hasJob === 'si' && formik.values?.jobReferences?.map((reference, index) => (
        <div key={index}>

            <h3 className="multiple-input-heading">Empleo {formik.values?.jobReferences.length > 1 && index + 1} </h3>

            <SectionHeading text={'Dirección de Empleo'} isSubHeading />

            <RadioInput
                formik={formik}
                fieldName={`jobReferences.${index}.sameAddress`}
                label={'¿La dirección del empleo es igual a la dirección domiciliaria?'}
                options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
                isGroup={true}
                isRequired={true}
            />

            {formik.values.jobReferences[index].sameAddress === 'no' &&
            <FormInputGroup>

                <JobAddressFields
                    formik={formik}
                    index={index}
                    dropDownOptions={dropDownOptions}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.street`}
                    label={'Avenida / Calle'}
                    maxLength={80}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.inmueble`}
                    label={'Tipo de Inmueble'}
                    options={formatDropDownOptions(dropDownOptions?.data?.propertyTypes?.filter(o => o.name !== 'CASA' && o.name !== 'RESIDENCIA' && o.name !== 'QUINTA'))}
                    isRequired={true}
                />

                {formik.values.jobReferences[index].inmueble &&
                <FormInputGroup>

                    <TextInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.housename`}
                        label={'Nombre / Identificación del Inmueble'}
                        maxLength={80}
                        isRequired={true}
                    />

                    <JobLocaleTypeFields
                        formik={formik}
                        index={index}
                        dropDownOptions={dropDownOptions}
                    />
                    
                </FormInputGroup>}

            </FormInputGroup>}

            <SectionHeading text={'Teléfono'} isSubHeading />

            <div className='form-field-phone-group'>

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.phoneType`}
                    label={'Tipo de Teléfono'}
                    options={formatDropDownOptions(dropDownOptions?.data?.phoneTypes)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.phoneAreaCode`}
                    label={'Código de Área'}
                    options={formatDropDownOptions(dropDownOptions?.data?.areaCodes)}
                    isRequired={true}
                />

                <MaskInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.phone`}
                    label={'Número de teléfono'}
                    mask={PHONE_MASK_FORMAT}
                    isRequired={true}
                />

            </div>

            <SectionHeading text={'Datos Empleo'} isSubHeading />

            <FormInputGroup>

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.constancy`}
                    label={'Constancia'}
                    options={formatDropDownOptions(dropDownOptions?.data?.constancies)}
                    isRequired={true}
                />

                {formik.values.jobReferences[index].constancy &&
                <>

                    {formik.values.jobReferences[index].constancy !== 'I' &&
                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.doctype`}
                        label={'Tipo de Documento'}
                        options={formatDropDownOptions(dropDownOptions?.data?.identificationTypes)}
                        isRequired={true}
                    />}

                    <CalendarInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.constancydate`}
                        label={'Fecha de la constancia de ingresos'}
                        isRequired={true}
                    />

                    {formik.values.jobReferences[index].constancy !== 'I' &&
                    <div className='form-field-rif-group'>
                        <DropDownInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.rifType`}
                            label={'Tipo RIF'}
                            options={formatDropDownOptions(dropDownOptions?.data?.identificationTypes)}
                            isRequired={true}
                        />
                            
                        <MaskInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.rif`}
                            label={'RIF'}
                            mask={RIF_MASK_FORMAT}
                            isRequired={true}
                        />
                    </div>}
                    
                    {formik.values.jobReferences[index].constancy !== 'NP' &&
                    <CurrencyInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.salary`}
                        label={formik.values.jobReferences[index].constancy === 'I' ? 'Ingresos Mensuales' : 'Sueldo'}
                        isRequired={true}
                    />}

                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.jobcurency`}
                        label={'Moneda'}
                        options={formatDropDownOptions(dropDownOptions?.data?.currencies)}
                        isRequired={true}
                    />

                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.othercurrency`}
                        label={'Otra moneda'}
                        options={formatDropDownOptions(dropDownOptions?.data?.currencies)}
                        isRequired={true}
                    />

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.contracttype`}
                        label={'Tipo de Contrato'}
                        options={formatDropDownOptions(dropDownOptions?.data?.typeOfContracts)}
                        isRequired={true}
                    />}

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <TextInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.company`}
                        label={'Compañia'}
                        maxLength={80}
                        isRequired={true}
                    />}

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <TextInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.activity`}
                        label={'Cargo que desempeña'}
                        maxLength={80}
                        isRequired={true}
                    />}

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <CalendarInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.jobdate`}
                        label={'Fecha de ingreso'}
                        isRequired={true}
                    />}

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.jobrole`}
                        label={'Rol'}
                        options={formatDropDownOptions(dropDownOptions?.data?.companyRoles)}
                        isRequired={true}
                    />}

                    <TextInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.jobemail`}
                        label={'Dirección Email'}
                        maxLength={80}
                        isRequired={true}
                    />

                    {formik.values.jobReferences[index].constancy !== 'I' &&
                    <TextInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.jobbranch`}
                        label={'Ramo del negocio'}
                        maxLength={80}
                        isRequired={true}
                    />}

                    {formik.values.jobReferences[index].constancy === 'I' &&
                    <DropDownInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.othericomes`}
                        label={'Actividad generadora de ingresos'}
                        options={formatDropDownOptions(dropDownOptions?.data?.otherIncomes)}
                        isRequired={true}
                    />}

                    {(formik.values.jobReferences[index].constancy !== 'NP' && formik.values.jobReferences[index].constancy !== 'I') &&
                    <CurrencyInput
                        formik={formik}
                        fieldName={`jobReferences.${index}.bonus`}
                        label={'Otros ingresos por Bonificación'}
                    />}

                </>}

            </FormInputGroup>

            {formik.values.jobReferences[index].constancy === 'E' &&
            <FileInput
                formik={formik}
                fieldName={`jobReferences.${index}.constancy`}
                label={'Constancia de empleo'}
                isRequired={true}
            />}

            {(formik.values.jobReferences[index].constancy === 'NP' || formik.values.jobReferences[index].constancy === 'I') &&
            <FileInput
                formik={formik}
                fieldName={`jobReferences.${index}.incomeAssurance`}
                label={'Informe de Aseguramiento de Ingresos'}
                isRequired={true}
            />}

            {formik.values.jobReferences[index].constancy === 'NP' &&
            <>

            <SectionHeading text={'Negocio'} isSubHeading />

            <FormInputGroup>

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessName`}
                    label={'Nombre de la Empresa'}
                    maxLength={80}
                    isRequired={true}
                />

                <CurrencyInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessincome`}
                    label={'Ingresos Por moneda'}
                    isRequired={true}
                />

                <CalendarInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessFoundingDate`}
                    label={'Fecha de constitución del negocio propio'}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessRegistryName`}
                    label={'Nombre del registro'}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessRegistryNumber`}
                    label={'Número de registro'}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessRegistryBook`}
                    label={'Número de libro'}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.businessFolio`}
                    label={'Folio'}
                    isRequired={true}
                />

            </FormInputGroup>
            
            </>}

            {formik.values.jobReferences[index].constancy === 'NP' &&
            <>
                <SectionHeading text={'Principales proveedores'} isSubHeading />

                {formik.values.jobReferences[index].businessSuppliers?.map((reference, i) => (
                <div key={i}>

                    <h3 className="multiple-input-heading multiple-input-heading-small">Proveedor {i + 1} </h3>

                    <FormInputGroup>

                        <TextInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessSuppliers.${i}.name`}
                            label={'Nombre'}
                            isRequired={true}
                        />

                        <JobProviderAddressFields
                            formik={formik}
                            index={index}
                            i={i}
                            dropDownOptions={dropDownOptions}
                        />

                        <NumberInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessSuppliers.${i}.RIF`}
                            label={'RIF'}
                            isRequired={true}
                        />
                        
                        {formik.values.jobReferences[index].businessSuppliers[i].country !== '0' &&
                        <TextInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessSuppliers.${i}.location`}
                            label={'Ubicación'}
                            isRequired={true}
                        />}

                    </FormInputGroup>
                
                </div>))}

                <div className='multiple-input-actions'>

                    <ButtonWithIcon
                        action={() => addItemToArray(formik, `jobReferences.${index}.businessSuppliers`, initialValues.jobReferences[0].businessSuppliers[0])}
                        label={'Añadir Proveedor'}
                        disabled={formik.values?.jobReferences[index].businessSuppliers?.length === 3}
                    />

                    <ButtonWithIcon
                        action={() => removeItemFromArray(formik, `jobReferences.${index}.businessSuppliers`)}
                        icon={'delete'}
                        disabled={formik.values?.jobReferences[index].businessSuppliers?.length === 1}
                    />

                </div>

            </>}


            {formik.values.jobReferences[index].constancy === 'NP' &&
            <>
                <SectionHeading text={'Clientes'} isSubHeading />

                {formik.values.jobReferences[index].businessClients?.map((reference, i) => (
                <div key={i}>
                
                    <h3 className="multiple-input-heading multiple-input-heading-small">Cliente {i + 1} </h3>
                    
                    <FormInputGroup>

                        <TextInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessClients.${i}.name`}
                            label={'Nombre'}
                            isRequired={true}
                        />

                        <DropDownInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessClients.${i}.country`}
                            label={'País'}
                            options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                            isRequired={true}
                        />

                        <NumberInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessClients.${i}.RIF`}
                            label={'RIF'}
                            isRequired={true}
                        />

                        <TextInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessClients.${i}.incomeDeclaration`}
                            label={'Declaración de bonificaciones/ingresos en otras monedas'}
                            isRequired={true}
                        />

                        <DropDownInput
                            formik={formik}
                            fieldName={`jobReferences.${index}.businessClients.${i}.virtualCurrency`}
                            label={'Uso de moneda virtual'}
                            options={formatDropDownOptions(dropDownOptions?.data?.virtualCurrencies)}
                            isRequired={true}
                        />

                    </FormInputGroup>
                    
                </div>))}

                <div className='multiple-input-actions'>

                    <ButtonWithIcon
                        action={() => addItemToArray(formik, `jobReferences.${index}.businessClients`, initialValues.jobReferences[0].businessClients[0])}
                        label={'Añadir Cliente'}
                        disabled={formik.values?.jobReferences[index].businessClients?.length === 3}
                    />

                    <ButtonWithIcon
                        action={() => removeItemFromArray(formik, `jobReferences.${index}.businessClients`)}
                        icon={'delete'}
                        disabled={formik.values?.jobReferences[index].businessClients?.length === 1}
                    />

                </div>

            </>}

        </div>))}

        {formik.values?.hasJob === 'si' &&
        <>
            <ButtonWithIcon
                action={() => addItemToArray(formik, 'jobReferences', initialValues.jobReferences[0])}
                label={'Añadir Empleo'}
            />

            <ButtonWithIcon
                action={() => removeItemFromArray(formik, 'jobReferences')}
                icon={'delete'}
                disabled={formik.values?.jobReferences?.length === 1}
            />
        </>}

        <div className='form-actions'>

            <Button
                action={() => goToPreviousModule(1)}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
