import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

// Hooks

import GetWindowSize from '../../hooks/GetWindowSize';

// Services

import { handleResetFormSteps } from '../../store/forms/forms.actions';

// Components

import Button from '../../components/UI/Button/Button';
import DownloadDocumentButton from '../../components/UI/DownloadDocumentButton/DownloadDocumentButton';

// Images

import icnSuccess from './Images/icn-success.png';
import btnAppStore from './Images/btn-app-store.png';
import btnGooglePlay from './Images/btn-google-play.png';
import imgSmartPhoneLine from './Images/img-smartphone-line.png';
import imgSmartPhone from './Images/img-smartphone.jpg';

// Styles

import './Styles.scss';

export default function Success() {
  const { windowSize } = GetWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleResetFormSteps());
  }, [dispatch])
  

  return (
    <div className='page-wrapper success-page'>

      {/* Succes Message */}

      <div className='container'>

        <div className='success-message'>

          <img src={icnSuccess} alt='' />

          <h1>
            Felicitaciones<br/>
            Ya eres cliente Bancaribe
          </h1>

          <h2>Completaste los pasos para el registro</h2>

        </div>

      </div>


      {/* Affiliate Invitation */}

      <div className='success-affiliate'>

        <div className='container'>

          <div className='success-affiliate-details'>

            <div className='success-affiliate-message'>
            
              <h2>Te hemos enviado un código de seguridad al correo que nos indicaste</h2>
              <p>Ingresa con ese código a nuestra APP y afíliate.</p>

            </div>

            <div className='success-affiliate-action'>
            
              <Button
                type="button"
                action={null}
                additionalStyles={windowSize?.innerWidth >= 769 ? {width: '50%', fontSize: '1.25rem', margin: 0} : {margin: 0}}
                label={'Afíliate'}
              />

            </div>

          </div>
        
        </div>

      </div>


      {/* Download Mobile App */}

      <div className='container'>

        <div className='success-mobile-app'>

          <div className="success-mobile-app-download">

            <h4>Descarga nuestra App</h4>

            <p>Porque sabemos que tu tiempo es oro, gestiona tus finanzas desde donde estés. Disfruta de todas las bondades que te ofrece Mi Conexión Bancaribe Digital en cualquier lugar, desde tu smartphone.</p>

            <p>Queremos acompañarte a donde lo necesites</p>

            <div className="success-mobile-app-download-links">
              <ul>
                <li>
                  <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">
                    <img src={btnAppStore} alt="Descargar en App Store para iOS"/></a>
                  </li>
                <li>
                  <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">
                    <img src={btnGooglePlay} alt="Descargar en Google Play para Android"/>
                    </a>
                  </li>
              </ul>

            </div>

            <img className='success-mobile-app-line' src={imgSmartPhoneLine} alt="" />

          </div>

          <div className="success-mobile-app-image">

            <img src={imgSmartPhone} alt="" />

          </div>

        </div>

      </div>


      {/* Download Documents */}

      <div className='container'>

        <div className='success-download-documents'>

          <h5>Estos documentos son parte de tu expediente, los puedes descargar para tu archivo.</h5>

          <div className='success-download-documents-buttons'>

            <DownloadDocumentButton
              label={'Ficha del Cliente'}
              previewAction={null}
              downloaDAction={null}
            />

            <DownloadDocumentButton
              label={'Certificado de conocimiento de contrato'}
              previewAction={null}
              downloaDAction={null}
            />

          </div>

        </div>

      </div>

    </div>
  )
}
