import React, {useState, useEffect} from 'react'

// Hooks

import GetStatesByCountry from '../../../../hooks/GetStatesByCountry';
import GetCitiesByState from '../../../../hooks/GetCitiesByState';
import GetMunicipalitiesByCity from '../../../../hooks/GetMunicipalitiesByCity';
import GetParishesByMunicipality from '../../../../hooks/GetParishesByMunicipality';
import GetSectorsByState from '../../../../hooks/GetSectorsByState';

// Components

import DropDownInput from '../../../UI/FormInput/DropDownInput';

// Utilities

import { formatDropDownOptions } from '../../../../utilities/helper-functions';

export default function JobAddressFields({dropDownOptions, formik, index}) {
    const { handleGetStatesByCountry } = GetStatesByCountry();
    const { handleGetCitiesByState } = GetCitiesByState();
    const { handleGetMunicipalitiesByCity } = GetMunicipalitiesByCity();
    const { handleGetParishesByMunicipality } = GetParishesByMunicipality();
    const { handleGetSectorsByState } = GetSectorsByState();
    // States
    const [getStatesIsLoading, setGetStatesIsLoading] = useState(false);
    const [getStatesError, setGetStatesError] = useState(null);
    const [states, setStates] = useState([]);
    // Cities
    const [getCitiesIsLoading, setGetCitiesIsLoading] = useState(false);
    const [getCitiesError, setGetCitiesError] = useState(null);
    const [cities, setCities] = useState([]);
    // Municipalities
    const [getMunicipalitiesIsLoading, setGetMunicipalitiesIsLoading] = useState(false);
    const [getMunicipalitiesError, setGetMunicipalitiesError] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    // Parishes
    const [getParishesIsLoading, setGetParishesIsLoading] = useState(false);
    const [getParishesError, setGetParishesError] = useState(null);
    const [parishes, setParishes] = useState([]);
    // Sectors
    const [getSectorsIsLoading, setGetSectorsIsLoading] = useState(false);
    const [getSectorsError, setGetSectorsError] = useState(null);
    const [sectors, setSectors] = useState([]);

    // Get the States by Country

    useEffect(() => {
        if (formik.values.jobReferences[index].country) {
            handleGetStatesByCountry(
                formik.values.jobReferences[index].country,
                setGetStatesIsLoading,
                setGetStatesError,
                setStates
            );
        }
    }, [handleGetStatesByCountry, formik.values.jobReferences[index].country])


    // Get the Cities by State

    useEffect(() => {
        if (formik.values.jobReferences[index].entity) {
            handleGetCitiesByState(
                formik.values.jobReferences[index].entity,
                setGetCitiesIsLoading,
                setGetCitiesError,
                setCities
            );
        }
    }, [handleGetCitiesByState, formik.values.jobReferences[index].entity])


    // Get the Municipalities by City

    useEffect(() => {
        if (formik.values.jobReferences[index].city) {
            handleGetMunicipalitiesByCity(
                formik.values.jobReferences[index].city,
                setGetMunicipalitiesIsLoading,
                setGetMunicipalitiesError,
                setMunicipalities,
            );
        }
    }, [handleGetMunicipalitiesByCity, formik.values.jobReferences[index].city])


    // Get the Parishes by Municipality

    useEffect(() => {
        if (formik.values.jobReferences[index].municipality) {
            handleGetParishesByMunicipality(
                formik.values.jobReferences[index].municipality,
                setGetParishesIsLoading,
                setGetParishesError,
                setParishes
            );
        }
    }, [handleGetParishesByMunicipality, formik.values.jobReferences[index].municipality])


    // Get the Sectors by State

    useEffect(() => {
        if (formik.values.jobReferences[index].entity) {
            handleGetSectorsByState(
                formik.values.jobReferences[index].entity,
                setGetSectorsIsLoading,
                setGetSectorsError,
                setSectors
            );
        }
    }, [handleGetSectorsByState, formik.values.jobReferences[index].entity])

    return (
        <>
            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.country`}
                label={'País'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.entity`}
                label={'Entidad'}
                options={formatDropDownOptions(states)}
                isRequired={true}
                disabled={states?.length === 0 || getStatesIsLoading || getStatesError}
                loading={getStatesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.city`}
                label={'Ciudad'}
                options={formatDropDownOptions(cities)}
                isRequired={true}
                disabled={cities?.length === 0 || getCitiesIsLoading || getCitiesError}
                loading={getCitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.municipality`}
                label={'Municipio'}
                options={formatDropDownOptions(municipalities)}
                isRequired={true}
                disabled={municipalities?.length === 0 || getMunicipalitiesIsLoading || getMunicipalitiesError}
                loading={getMunicipalitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.church`}
                label={'Parroquia'}
                options={formatDropDownOptions(parishes)}
                isRequired={true}
                disabled={parishes?.length === 0 || getParishesIsLoading || getParishesError}
                loading={getParishesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={`jobReferences.${index}.urban`}
                label={'Urbanización, Sector, Barrio'}
                options={formatDropDownOptions(sectors)}
                isRequired={true}
                disabled={sectors?.length === 0 || getSectorsIsLoading || getSectorsError}
                loading={getSectorsIsLoading}
            />

        </>
    )
}
