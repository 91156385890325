import React from 'react'

import { useDispatch } from 'react-redux'

// Hooks

import GetWindowSize from '../../../hooks/GetWindowSize';

// Styles

import './Styles.scss';

// Images

import icnStepComplete from './Images/icn-step-complete.png';

export default function FormStepper({modules, currentModule, currentStep, setModule, setFormStep}) {
  const { windowSize } = GetWindowSize();
  const dispatch = useDispatch();

  return (
    <div className='form-stepper'>

        {modules.map((module, index) => 
          <div className={`form-step ${currentModule >= index ? 'form-step-active' : ''}`} key={index}>
            <p className='form-step-name'>
              <span className={`form-step-number ${currentModule > index ? 'form-step-number-completed' : ''}`} onClick={() => {
                if (currentModule > index) {
                  dispatch(setModule(index));
                  dispatch(setFormStep(0));
                }
              }}>
                {currentModule > index ? <img src={icnStepComplete} className="form-step-icon" alt="" /> : index + 1}
              </span>
              <span className='form-step-text'>
                {windowSize?.innerWidth >= 881 ? module.name : module.mobileName}
              </span>
            </p>

            {modules.length !== index + 1 &&
            <div className='form-step-bar'>
              {currentModule >= index &&
              <>
                {windowSize?.innerWidth >= 881 ? (
                  <div 
                    className='form-step-bar-progress'
                    style={currentModule > index ? {height: `100%`} : {height: `${currentStep/module.steps * 100}%`}}
                  >
                  </div>
                ) : (
                  <div 
                    className='form-step-bar-progress'
                    style={currentModule > index ? {width: `100%`} : {width: `${currentStep/module.steps * 100}%`}}
                  >
                  </div>
                )}
              </>}
            </div>}
          </div>
        )}
    </div>
  )
}
