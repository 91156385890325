import React from 'react';

// Components

import TextInput from '../../../UI/FormInput/TextInput';

export default function LocaleTypeFields({formik}) {
  return (
    <>
        {(formik.values?.inmueble === '1' || formik.values?.inmueble === '2') ? (
        <>
            <TextInput
                formik={formik}
                fieldName={'houselevel'}
                label={'Nivel'}
                maxLength={80}
                isRequired={true}
            />
        </>
        ) : (formik.values?.inmueble === '3' || formik.values?.inmueble === '5') ? (
        <>
            <TextInput
                formik={formik}
                fieldName={'housetower'}
                label={'Torre'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
              formik={formik}
              fieldName={'housefloor'}
              label={'Piso'}
              maxLength={80}
              isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'houseapartment'}
                label={'Apartamento'}
                maxLength={80}
                isRequired={true}
            />
        </>
        ) : (null)}
    </>
  )
}
