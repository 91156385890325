import React from 'react'

import './Styles.scss';

export default function InputWrapper(props) {
    const {
        formik,
        fieldName,
        label,
        helpText,
        isRequired,
        isGroup,
        children
    } = props;

    return (
        <div className='input-container'>
            <div className={isGroup ? 'input-group-wrapper' : 'input-field-wrapper'}>

                <label
                    htmlFor={fieldName}
                    className={`${formik.errors[fieldName] && formik.touched[fieldName] ? 'p-error' : ''} ${isGroup && !(formik.errors[fieldName] && formik.touched[fieldName]) ? 'input-group-label' : ''}`}>
                   
                    {label}
                    {isRequired && <span>*</span>}

                </label>

                {/* Input Field */}

                {children}

            </div>

            {helpText &&
            <small className="p-helptext">{helpText}</small>}

            {formik.touched[fieldName] && formik.errors[fieldName] &&
            <small className="p-error">{formik.touched[fieldName] && formik.errors[fieldName]}</small>}

        </div>
    )
}
