// Form initial values

// Initial values

import { initialValues as PersonalInfoInitialValues } from "./1.PersonalInfo/InitialValues"
import { initialValues as AdditionalInfoInitialValues } from "./2.AdditionalInfo/InitialValues"
import { initialValues as JobInfoInitialValues } from "./3.JobInfo/InitialValues"
import { initialValues as ProductInfoInitialValues } from "./4.ProductInfo/InitialValues"

export const FormInitialValues = {
    ...PersonalInfoInitialValues,
    ...AdditionalInfoInitialValues,
    ...JobInfoInitialValues,
    ...ProductInfoInitialValues,
};