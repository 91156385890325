import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss'

export default function ButtonIconOutline({label, action, type, disabled, isLoading, additionalStyles}) {
  return (
    <button className={"btn btn-outline"}
      type={type ? type : null}
      onClick={() => action()}
      style={additionalStyles ? additionalStyles : {}}
      disabled={disabled || isLoading}
    >
      <FontAwesomeIcon className='btn-outline-icon' icon={faDownload} /> {label}
    </button>
  )
}
