import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";

// Services

import VUService from '../../services/vu.service';

// Constants

import { RESPONSE_CODES } from '../../config/constants';

// Components

import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';

// Styles

import './Styles.scss';

export default function OnBoarding() {
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [submitError, setSubmitError] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const navigate = useNavigate();

  const getDocumentInfo = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
        const operationInfo = localStorage.getItem('operationInfo');
        const operationInfoData = operationInfo ? JSON.parse(operationInfo) : null;
        let data = {};

        if (operationInfoData) {
          data = {
            operationGuid: operationInfoData.operationGuid,
            operationId: String(operationInfoData.operationId),
            userName: operationInfoData.username,
          }
        }

        const response = await VUService.getDocumentInfo(data);

        setIsSubmitting(false);

        if (response?.data?.code === RESPONSE_CODES.getDocumentInfoSuccess) {
            const documentInformation = {
              data: response?.data?.ocr,
              extra: JSON.parse(response?.data?.ocr?.extra?.additional),
              mrz: JSON.parse(response?.data?.ocr?.extra?.mrz),
              email:  operationInfoData?.username
            }
            setDocumentInfo(documentInformation);
        } else {
            setSubmitError(response?.data?.message);
        }
    } catch(error) {
        setIsSubmitting(false);
        setSubmitError(error.response?.data?.message || error.message);
    }
  }

  useEffect(() => {
    getDocumentInfo();
  }, [])

  // End the VU Onboaring Process, delete the stored api key and pass the document info and document image to the next page

  const concludeVUProcess = () => {
    localStorage.removeItem('operationInfo');
    
    navigate("/registro", { state: { documentInfo: documentInfo } });
  }

  return (
    <div className="page-wrapper">
      <div className="container">

        {/* ONBOARDING PROCESS */}
        {console.log(documentInfo)}
        {(isSubmitting || submitError) &&
          <Loader 
            message={'Cargando datos...'}
            error={submitError}
            action={() => getDocumentInfo()}
        />}

        {/* ONBOARDING GET USER INFO */}

        {(!isSubmitting && documentInfo) &&
        <div className='onboarding-page-get-info'>

          <div className='onboarding-page-get-info-heading'>
          
            <h1>Onboarding</h1>

            <p>Proceso de Onboarding exitoso, por favor verifique la infomación obtenida de su Documento de Identidad.</p>

          </div>

          <div className='get-info-details'>

            <div className='get-info-details-entries'>
  
              <p className='get-inf-details-id'>402-2120787-7</p>
              <p><strong>Pablo Alejandro García González</strong></p>

              <div className='get-info-details-entry'>
                <p>Fecha de Nacimiento</p>
                <p><strong>23 Octubre 1991</strong></p>
              </div>

              <div className='get-info-details-entry'>
                <p>Estado Civil</p>
                <p><strong>Soltero</strong></p>
              </div>

              <div className='get-info-details-entry'>
                <p>Fecha de Expedición</p>
                <p><strong>30 Julio 2020</strong></p>
              </div>

              <div className='get-info-details-entry'>
                <p>Fecha de Vencimiento</p>
                <p><strong>15 Agosto 2030</strong></p>
              </div>

              <div className='get-info-details-entry'>
                <p>Nacionalidad</p>
                <p><strong>Venezolano</strong></p>
              </div>

            </div>

          </div>

          <Button
              type="button"
              action={() =>  window.location.href = "vu/onboarding/onboarding.html"}
              label={'Regresa'}
              secondaryStyle={true}
          />
          
          <Button
              type="button"
              action={() => concludeVUProcess()}
              label={'Continua'}
          />

        </div>}
      </div>
    </div>
  )
}
