import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import Alert from '../../../../UI/Alert/Alert';
import TextInput from '../../../../UI/FormInput/TextInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import CalendarInput from '../../../../UI/FormInput/CalendarInput';
import NumberInput from '../../../../UI/FormInput/NumberInput';
import FileInput from '../../../../UI/FormInput/FileInput';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';
import ButtonWithIcon from '../../../../UI/ButtonWithIcon/ButtonWithIcon';

// Utilities

import { addItemToArray, removeItemFromArray, formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { initialValues } from '../InitialValues';

export default function BankReferences({formik, formStep, setFormStep}) {
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
    const dispatch = useDispatch();

    return (
    <>
        <SectionHeading text={'Conozcámonos un poco más'} />

        <Alert text={'Para completar una solicitud de apertura de cuenta es obligatoria una referencia bancaria. En caso de no poseer relación con ninguna entidad financiera, completa una referencia personal.'} />       

        <RadioInput
            formik={formik}
            fieldName={'hasBankReference'}
            label={'¿Posee referencia bancaria?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values?.hasBankReference === 'si' && formik.values?.bankReferences?.map((reference, index) => (
        <div key={index}>

            <h3 className="multiple-input-heading">Referencia {formik.values?.bankReferences.length > 1 && index + 1} </h3>

            <FormInputGroup>

                <CalendarInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refdate`}
                    label={'Fecha de Constancia'}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refbank`}
                    label={'Banco'}
                    options={formatDropDownOptions(dropDownOptions?.data?.banks)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refaccounttype`}
                    label={'Tipo de Cuenta'}
                    options={formatDropDownOptions(dropDownOptions?.data?.accountTypes)}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refaccountnumber`}
                    label={'Número de Cuenta'}
                    maxLength={80}
                    isRequired={true}
                />

                <CalendarInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refopendate`}
                    label={'Fecha de Apertura'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refaccountaverage`}
                    label={'Número de Cifras Promedio'}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refaccountaveragetipe`}
                    label={'Tipo de Cifras Promedio'}
                    options={formatDropDownOptions(dropDownOptions?.data?.typeFigures)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.refaccountcalification`}
                    label={'Calificación'}
                    options={formatDropDownOptions(dropDownOptions?.data?.customerTypes)}
                    isRequired={true}
                />

            </FormInputGroup>

            <TextInput
                formik={formik}
                fieldName={`bankReferences.${index}.refaobservation`}
                label={'Observación'}
                maxLength={80}
                isRequired={true}
            />

        <FileInput
            formik={formik}
            fieldName={`bankReferences.${index}.constancy`}
            label={'Constancia referencia bancaria.'}
            isRequired={true}
        />

        </div>))}

        {formik.values?.hasBankReference === 'si' &&
        <>
            <ButtonWithIcon
                action={() => addItemToArray(formik, 'bankReferences', initialValues.bankReferences[0])}
                label={'Añadir Referencia'}
            />

            <ButtonWithIcon
                action={() => removeItemFromArray(formik, 'bankReferences')}
                icon={'delete'}
                disabled={formik.values?.bankReferences?.length === 1}
            />
        </>}

        <div className='form-actions'>

            <Button
                action={() => dispatch(setFormStep(formStep - 1))}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
