import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

// Hooks

import CreateEntry from '../../../hooks/CreateEntry';

// Services

import { getDropDownOptions } from '../../../store/options/options.actions';
import { handleSetFormValues, handleSetShowModuleIntro, handleSetModule, handleSetFormStep } from '../../../store/forms/forms.actions';

// Components

import FormWrapper from '../../UI/FormWrapper/FormWrapper';
import FormStepper from '../../UI/FormStepper/FormStepper';
import Loader from '../../UI/Loader/Loader';

// Modules

import FormModuleIntro from './FormModuleIntro/FormModuleIntro';
import PersonalInfo from './1.PersonalInfo/PersonalInfo';
import AdditionalInfo from './2.AdditionalInfo/AdditionalInfo';
import JobInfo from './3.JobInfo/JobInfo';
import ProductInfo from './4.ProductInfo/ProductInfo';

// Validation

import { FormInitialValues } from './FormInitialValues';

// Data

import { FORM_MODULES } from './FormModules';

// Styles

import './Styles.scss';

export default function UserDetailsForm() {
  const { isSubmitting, submitError, createNewEntry } = CreateEntry();
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const showModuleIntro = useSelector((state) => state.forms.showModuleIntro);
  const formValues = useSelector((state) => state.forms.values);
  const module = useSelector((state) => state.forms.module);
  const formStep = useSelector((state) => state.forms.formStep);
  const dispatch = useDispatch();

  // Setup form with formik

  const formik = useFormik({
    initialValues: formValues || FormInitialValues,
    // initialValues: FormInitialValues,
    validationSchema: FORM_MODULES[module]?.schema[formStep],
    onSubmit: (values, actions) => {
      sendFormRequest(values, actions);
    },
  });

  useEffect(() => {
    dispatch(getDropDownOptions())
  }, [dispatch])

  // Send Form Request

  const sendFormRequest = async (values, actions) => {
    const currentModule = FORM_MODULES[module];

    if ((formStep + 1 === currentModule.steps) && (module + 1 === FORM_MODULES.length)) {

      createNewEntry(values);

    } else if ((formStep + 1 === currentModule.steps) && (module + 1 !== FORM_MODULES.length)) {

      dispatch(handleSetShowModuleIntro(true));
      dispatch(handleSetModule(module + 1));
      dispatch(handleSetFormStep(0));

    } else if (formStep + 1 !== currentModule.steps) {

      if (values.hasBankReference === 'si' && (module === 1 && formStep === 1)) {
        dispatch(handleSetFormStep(formStep + 2));
      } else {
        dispatch(handleSetFormStep(formStep + 1));
      }

      actions.setTouched({});
      actions.setSubmitting(false);

    }

    // Scroll to top on form step change 

    window.scrollTo({ top: 0, behavior: "smooth"});
  }

  // Go to the previous form module

  const goToPreviousModule = (moduleNumber) => {
    const previousModuleLength = FORM_MODULES[moduleNumber]?.steps;

    dispatch(handleSetModule(moduleNumber));
    dispatch(handleSetFormStep(previousModuleLength - 1));

    // Scroll to top on form step change 

    window.scrollTo({ top: 0, behavior: "smooth"});
  }

  // Save form values on step change

  useEffect(() => {
    dispatch(handleSetFormValues(formik.values))
  }, [dispatch, formStep])

  return (
    <>
      {(dropDownOptions.isLoading || dropDownOptions.error) &&
      <Loader 
        message={'Cargando...'}
        error={dropDownOptions.error}
        action={() => dispatch(getDropDownOptions())}
      />}
      
      {(showModuleIntro && !dropDownOptions.isLoading && !dropDownOptions.error) &&
      <FormModuleIntro
        modules={FORM_MODULES}
        currentModule={module}
        handleSetShowModuleIntro={handleSetShowModuleIntro}
      />}

      {(!showModuleIntro && !dropDownOptions.isLoading && !dropDownOptions.error) &&
      <FormWrapper>

        <FormStepper
          modules={FORM_MODULES}
          currentModule={module}
          currentStep={formStep + 1}
          setModule={handleSetModule}
          setFormStep={handleSetFormStep}
        />
        
        <form className='form-container' onSubmit={formik.handleSubmit}>

          {/* Form Error Message */}

          {submitError && <p className="form-error-message">{submitError}</p>}

          {/* 1. Personal Info */}

          {module === 0 &&
          <PersonalInfo
            formik={formik}
            formStep={formStep}
            setFormStep={handleSetFormStep}
          />}

          {/* 2. Additional Info */}

          {module === 1 &&
          <AdditionalInfo
            formik={formik}
            formStep={formStep}
            setModule={handleSetModule}
            setFormStep={handleSetFormStep}
            goToPreviousModule={goToPreviousModule}
          />}

          {/* 3. Job Info */}

          {module === 2 &&
          <JobInfo
            formik={formik}
            formStep={formStep}
            setFormStep={handleSetFormStep}
            goToPreviousModule={goToPreviousModule}
          />}

          {/* 4. Product Info */}

          {module === 3 &&
          <ProductInfo
            formik={formik}
            formStep={formStep}
            setModule={handleSetModule}
            setFormStep={handleSetFormStep}
            isSubmitting={isSubmitting}
            goToPreviousModule={goToPreviousModule}
          />}

        </form>
        
      </FormWrapper>}
    </>
  )
}