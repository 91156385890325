import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

// Components

import Button from '../Button/Button';

import './Styles.scss';

export default function Loader({message, error, action}) {
  return (
    <div className={'loader'}>
      {error ? (
        <>
          <FontAwesomeIcon className='loader-icon' icon={faCircleExclamation} />
          <p>{error}</p>
          <Button
            type="button"
            action={() => action()}
            label={'Reintentar'}
            secondaryStyle={true}
          />
        </>
      ) : (
        <>
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <p>{message}</p>
        </>
      )}
    </div>
  )
}
