// API
import OptionsService from '../../services/options.service';

import { 
  setgetDropDownOptionsLoading, setgetDropDownOptionsScuccess, setgetDropDownOptionsFailure,
 } from './options.reducers';


  // Get the list of all the dropdown options ====================================================================================================

  /**
   * @description Gets the list of all the dropdown options
   */

  export const getDropDownOptions = () => async (dispatch) => {
    dispatch(setgetDropDownOptionsLoading());

    try {

      const response = await OptionsService.getDropDownOptions();

      dispatch(setgetDropDownOptionsScuccess(response.data?.data));

    } catch(error) {

      dispatch(setgetDropDownOptionsFailure(error.response?.data?.message || error.message));
    }

  }