import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Components

import Header from './components/Header/Header';

// Pages

import UserAccess from "./pages/UserAccess/UserAccess";
import AccountType from "./pages/AccountType/AccountType";
import OnBoarding from "./pages/OnBoarding/OnBoarding";
import Register from "./pages/Register/Register";
import Success from "./pages/Success/Success";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<UserAccess />} />
        <Route path="/tipocuenta" element={<AccountType />} />
        <Route path="/infodedocumento" element={<OnBoarding />} />
        <Route path="/registro" element={<Register />} />
        <Route path="/success" element={<Success />} />
        <Route path="*" element={<Navigate to ="/" />}/>
      </Routes>
    </Router>
  );
}

export default App;
