import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Favor ingresar tu nombre'),
  age: yup
    .string()
    .required('Favor ingresar tu edad'),
  email: yup
    .string()
    .email('Ingrese un email válido.')
    .required('Favor ingresar tu correo')
});