import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import Alert from '../../../../UI/Alert/Alert';
import TextInput from '../../../../UI/FormInput/TextInput';
import MaskInput from '../../../../UI/FormInput/MaskInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import FileInput from '../../../../UI/FormInput/FileInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';
import ButtonWithIcon from '../../../../UI/ButtonWithIcon/ButtonWithIcon';

// Utilities

import { addItemToArray, removeItemFromArray, formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { IDENTIFICATION_MASK_FORMAT, PHONE_MASK_FORMAT } from '../../../../../config/constants';

import { initialValues } from '../InitialValues';

export default function PersonalReferences({formik, formStep, setFormStep}) {
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
    const dispatch = useDispatch();

    return (
    <>
      <SectionHeading text={'Conozcámonos un poco más'} />

        <Alert text={'Para completar una solicitud de apertura de cuenta es obligatoria una referencia personal.'} />

        {formik.values?.personalReferences?.map((reference, index) => (
        <div key={index}>

            <h3 className="multiple-input-heading">Referencia personal {formik.values?.personalReferences.length > 1 && index + 1} </h3>

            <FormInputGroup>

                <DropDownInput
                    formik={formik}
                    fieldName={`bankReferences.${index}.documentIdType`}
                    label={'Tipo de Identificación'}
                    options={formatDropDownOptions(dropDownOptions?.data?.identificationTypes)}
                    isRequired={true}
                />

                <MaskInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.documentId`}
                    label={'Número de identificación'}
                    mask={IDENTIFICATION_MASK_FORMAT}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.names`}
                    label={'Nombres completos'}
                    maxLength={80}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.firstLastName`}
                    label={'Primer apellido'}
                    maxLength={80}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.secondLastName`}
                    label={'Segundo apellido'}
                    maxLength={80}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.relationship`}
                    label={'Parentesco'}
                    options={formatDropDownOptions(dropDownOptions?.data?.relationships)}
                    isRequired={true}
                />

            </FormInputGroup>

            <div className='form-field-phone-group'>

                <DropDownInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneType1`}
                    label={'Tipo de Teléfono'}
                    options={formatDropDownOptions(dropDownOptions?.data?.phoneTypes)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneAreaCode1`}
                    label={'Código de Área'}
                    options={formatDropDownOptions(dropDownOptions?.data?.areaCodes)}
                    isRequired={true}
                />

                <MaskInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneNumber1`}
                    label={'Número de Teléfono'}
                    mask={PHONE_MASK_FORMAT}
                    isRequired={true}
                />

            </div>

            <div className='form-field-phone-group'>

                <DropDownInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneType2`}
                    label={'Tipo de Teléfono'}
                    options={formatDropDownOptions(dropDownOptions?.data?.phoneTypes)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneAreaCode2`}
                    label={'Código de Área'}
                    options={formatDropDownOptions(dropDownOptions?.data?.areaCodes)}
                    isRequired={true}
                />

                <MaskInput
                    formik={formik}
                    fieldName={`personalReferences.${index}.phoneNumber2`}
                    label={'Número de Teléfono'}
                    mask={PHONE_MASK_FORMAT}
                    isRequired={true}
                />

            </div>

            {formik.values?.hasBankReference === 'no' &&
            <FileInput
                formik={formik}
                fieldName={`personalReferences.${index}.constancy`}
                label={'Constancia referencia personal.'}
                isRequired={true}
            />}

        </div>))}

        <>
            <ButtonWithIcon
                action={() => addItemToArray(formik, 'personalReferences', initialValues.personalReferences[0])}
                label={'Añadir Referencia'}
            />

            <ButtonWithIcon
                action={() => removeItemFromArray(formik, 'personalReferences')}
                icon={'delete'}
                disabled={formik.values?.personalReferences?.length === 1}
            />
        </>

        <div className='form-actions'>

            <Button
                action={() => formik.values?.hasBankReference === 'no' ? dispatch(setFormStep(formStep - 1)) : dispatch(setFormStep(formStep - 2))}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
