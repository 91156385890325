import React, {useState, useEffect} from 'react'

import { useSelector } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import Alert from '../../../../UI/Alert/Alert';
import TextInput from '../../../../UI/FormInput/TextInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import MaskInput from '../../../../UI/FormInput/MaskInput';
import NumberInput from '../../../../UI/FormInput/NumberInput';
import CalendarInput from '../../../../UI/FormInput/CalendarInput';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';

// Hooks

import GetStatesByCountry from '../../../../../hooks/GetStatesByCountry';
import GetCitiesByState from '../../../../../hooks/GetCitiesByState';
import GetMunicipalitiesByCity from '../../../../../hooks/GetMunicipalitiesByCity';
import GetParishesByMunicipality from '../../../../../hooks/GetParishesByMunicipality';
import GetSectorsByState from '../../../../../hooks/GetSectorsByState';

// Utilities

import { formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { RIF_MASK_FORMAT, IDENTIFICATION_MASK_FORMAT } from '../../../../../config/constants';

export default function AboutYou({formik}) {
    const { handleGetStatesByCountry } = GetStatesByCountry();
    const { handleGetCitiesByState } = GetCitiesByState();
    const { handleGetMunicipalitiesByCity } = GetMunicipalitiesByCity();
    const { handleGetParishesByMunicipality } = GetParishesByMunicipality();
    const { handleGetSectorsByState } = GetSectorsByState();
    // Client States
    const [getStatesIsLoading, setGetStatesIsLoading] = useState(false);
    const [getStatesError, setGetStatesError] = useState(null);
    const [states, setStates] = useState([]);
    // Client Cities
    const [getCitiesIsLoading, setGetCitiesIsLoading] = useState(false);
    const [getCitiesError, setCitiesError] = useState(null);
    const [cities, setCities] = useState([]);
    // Apoderado States
    const [getApoderadoStatesIsLoading, setApoderadoGetStatesIsLoading] = useState(false);
    const [getApoderadoStatesError, setApoderadoGetStatesError] = useState(null);
    const [apoderadoStates, setApoderadoStates] = useState([]);
    // Apoderado Cities
    const [getApoderadoCitiesIsLoading, setApoderadoGetCitiesIsLoading] = useState(false);
    const [getApoderadoCitiesError, setApoderadoGetCitiesError] = useState(null);
    const [apoderadoCities, setApoderadoCities] = useState([]);
    // Apoderado Municipalities
    const [getApoderadoMunicipalitiesIsLoading, setApoderadoGetMunicipalitiesIsLoading] = useState(false);
    const [getApoderadoMunicipalitiesError, setApoderadoGetMunicipalitiesError] = useState(null);
    const [apoderadoMunicipalities, setApoderadoMunicipalities] = useState([]);
    // Apoderado Parishes
    const [getApoderadoParishesIsLoading, setApoderadoGetParishesIsLoading] = useState(false);
    const [getApoderadoParishesError, setApoderadoGetParishesError] = useState(null);
    const [apoderadoParishes, setApoderadoParishes] = useState([]);
    // Apoderado Sectors
    const [getApoderadoSectorsIsLoading, setApoderadoGetSectorsIsLoading] = useState(false);
    const [getApoderadoSectorsError, setApoderadoGetSectorsError] = useState(null);
    const [apoderadoSectors, setApoderadoSectors] = useState([]);
    
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);

    
    // Get the Client States by Country

    useEffect(() => {
        if (formik.values.birthplace) {
            handleGetStatesByCountry(
                formik.values.birthplace,
                setGetStatesIsLoading,
                setGetStatesError,
                setStates
            );
        }
    }, [handleGetStatesByCountry, formik.values.birthplace])


    // Get the Client Cities by State

    useEffect(() => {
        if (formik.values.birthState) {
            handleGetCitiesByState(
                formik.values.birthState,
                setGetCitiesIsLoading,
                setCitiesError,
                setCities
            );
        }
    }, [handleGetCitiesByState, formik.values.birthState])


    // Get the Apoderado States by Country

    useEffect(() => {
        if (formik.values.apoderadoBirthplace) {
            handleGetStatesByCountry(
                formik.values.apoderadoBirthplace,
                setApoderadoGetStatesIsLoading,
                setApoderadoGetStatesError,
                setApoderadoStates
            );
        }
    }, [handleGetStatesByCountry, formik.values.apoderadoBirthplace])


    // Get the Apoderado Cities by State

    useEffect(() => {
        if (formik.values.apoderadoBirthState) {
            handleGetCitiesByState(
                formik.values.apoderadoBirthState,
                setApoderadoGetCitiesIsLoading,
                setApoderadoGetCitiesError,
                setApoderadoCities
            );
        }
    }, [handleGetCitiesByState, formik.values.apoderadoBirthState])


    // Get the Apoderado Municipalities by City

    useEffect(() => {
        if (formik.values.apoderadoCity) {
            handleGetMunicipalitiesByCity(
                formik.values.apoderadoCity,
                setApoderadoGetMunicipalitiesIsLoading,
                setApoderadoGetMunicipalitiesError,
                setApoderadoMunicipalities,
            );
        }
    }, [handleGetMunicipalitiesByCity, formik.values.apoderadoCity])


    // Get the Apoderado Parishes by Municipality

    useEffect(() => {
        if (formik.values.apoderadoMunicipalty) {
            handleGetParishesByMunicipality(
                formik.values.apoderadoMunicipalty,
                setApoderadoGetParishesIsLoading,
                setApoderadoGetParishesError,
                setApoderadoParishes
            );
        }
    }, [handleGetParishesByMunicipality, formik.values.apoderadoMunicipalty])


    // Get the Apoderado Sectors by State

    useEffect(() => {
        if (formik.values.apoderadoBirthState) {
            handleGetSectorsByState(
                formik.values.apoderadoBirthState,
                setApoderadoGetSectorsIsLoading,
                setApoderadoGetSectorsError,
                setApoderadoSectors
            );
        }
    }, [handleGetSectorsByState, formik.values.apoderadoBirthState])

    return (
    <>
        <SectionHeading text={'Pablo ¡Queremos saber sobre ti!'} />

        <Alert text={'Estos datos son de vital importancia para comunicarnos contigo y ayudar a resolver cualquier duda o solicitud ¡Adelante!'} />

        <FormInputGroup>

            <TextInput
                formik={formik}
                fieldName={'name'}
                label={'Nombres'}
                maxLength={80}
                isRequired={true}
                isAlphabetic
            />

            <TextInput
                formik={formik}
                fieldName={'firstlastname'}
                label={'Primer Apellido'}
                maxLength={80}
                isRequired={true}
                isAlphabetic
            />

            <TextInput
                formik={formik}
                fieldName={'secondlastname'}
                label={'Segundo Apellido'}
                maxLength={80}
                isAlphabetic
            />

            <CalendarInput
                formik={formik}
                fieldName={'birthdate'}
                label={'Fecha de Nacimiento'}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'documentIdType'}
                label={'Tipo de Identificación'}
                options={formatDropDownOptions(dropDownOptions?.data?.identificationTypes)}
                isRequired={true}
            />
            

            <MaskInput
                formik={formik}
                fieldName={'documentId'}
                label={'Número de Identificación'}
                mask={IDENTIFICATION_MASK_FORMAT}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'gender'}
                label={'Género'}
                options={formatDropDownOptions(dropDownOptions?.data?.genders)}
                isRequired={true}
            />

            <div className='form-field-rif-group'>
            <DropDownInput
                formik={formik}
                fieldName={`clientRIFType`}
                label={'Tipo RIF'}
                options={formatDropDownOptions(dropDownOptions?.data?.identificationTypes)}
                isRequired={true}
            />
                
            <MaskInput
                formik={formik}
                fieldName={`clientRIF`}
                label={'RIF'}
                mask={RIF_MASK_FORMAT}
                isRequired={true}
            />
            </div>

            <DropDownInput
                formik={formik}
                fieldName={'nationality'}
                label={'Nacionalidad'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'otherNationality'}
                label={'Otra Nacionalidad'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'birthplace'}
                label={'Lugar de Nacimiento'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'birthState'}
                label={'Estado de Nacimiento'}
                options={formatDropDownOptions(states)}
                isRequired={true}
                disabled={states?.length === 0 || getStatesIsLoading || getStatesError}
                loading={getStatesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'birthcity'}
                label={'Ciudad de Nacimiento'}
                options={formatDropDownOptions(cities)}
                isRequired={true}
                disabled={cities?.length === 0 || getCitiesIsLoading || getCitiesError}
                loading={getCitiesIsLoading}
            />

            <NumberInput
                formik={formik}
                fieldName={'familyCharge'}
                label={'Carga familiar'}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'civilStatus'}
                label={'Estado Civil'}
                options={formatDropDownOptions(dropDownOptions?.data?.civilStatus)}
                isRequired={true}
            />

        </FormInputGroup>

        {(formik.values.civilStatus === 'C' || formik.values.civilStatus === 'U') &&
        <>
            <SectionHeading text={'Datos del conyugue'} hideRequiredFieldsMessage />

            <FormInputGroup>

                <TextInput
                    formik={formik}
                    fieldName={'conyugeNames'}
                    label={'Nombres'}
                    maxLength={80}
                    isRequired={true}
                    isAlphabetic
                />

                <TextInput
                    formik={formik}
                    fieldName={'conyugeLastNames'}
                    label={'Apellidos'}
                    maxLength={80}
                    isRequired={true}
                    isAlphabetic
                />

                <MaskInput
                    formik={formik}
                    fieldName={'conyugDocumentId'}
                    label={'Número de Identificación'}
                    mask={IDENTIFICATION_MASK_FORMAT}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={'conyugeIncome'}
                    label={'Fuente de ingresos del conyuge'}
                    options={formatDropDownOptions(dropDownOptions?.data?.sourceOfFunds)}
                    isRequired={true}
                />

            </FormInputGroup>
        </>}

        <RadioInput
            formik={formik}
            fieldName={'hasApoderado'}
            label={'¿Posees un autorizado o apoderado?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values?.hasApoderado === 'si' &&
        <FormInputGroup>

            <MaskInput
                formik={formik}
                fieldName={'apoderadoDocumentId'}
                label={'Número de documento'}
                mask={IDENTIFICATION_MASK_FORMAT}
                isRequired={true}
            />

            <CalendarInput
                formik={formik}
                fieldName={'apoderadoBirthdate'}
                label={'Fecha de Nacimiento'}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoBirthplace'}
                label={'Lugar de Nacimiento'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoBirthState'}
                label={'Estado de Nacimiento'}
                options={formatDropDownOptions(apoderadoStates)}
                isRequired={true}
                disabled={apoderadoStates?.length === 0 || getApoderadoStatesIsLoading || getApoderadoStatesError}
                loading={getApoderadoStatesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoCity'}
                label={'Ciudad'}
                options={formatDropDownOptions(apoderadoCities)}
                isRequired={true}
                disabled={apoderadoCities?.length === 0 || getApoderadoCitiesIsLoading || getApoderadoCitiesError}
                loading={getApoderadoCitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoMunicipalty'}
                label={'Municipio'}
                options={formatDropDownOptions(apoderadoMunicipalities)}
                isRequired={true}
                disabled={apoderadoMunicipalities?.length === 0 || getApoderadoMunicipalitiesIsLoading || getApoderadoMunicipalitiesError}
                loading={getApoderadoMunicipalitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoChurch'}
                label={'Parroquia'}
                options={formatDropDownOptions(apoderadoParishes)}
                isRequired={true}
                disabled={apoderadoParishes?.length === 0 || getApoderadoParishesIsLoading || getApoderadoParishesError}
                loading={getApoderadoParishesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'apoderadoUrban'}
                label={'Urbanización, Sector, Barrio'}
                options={formatDropDownOptions(apoderadoSectors)}
                isRequired={true}
                disabled={apoderadoSectors?.length === 0 || getApoderadoSectorsIsLoading || getApoderadoSectorsError}
                loading={getApoderadoSectorsIsLoading}
            />

            <TextInput
                formik={formik}
                fieldName={'apoderadoStreet'}
                label={'Avenida / Calle'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'apoderadoContactDetails'}
                label={'Datos de contacto'}
                isRequired={true}
            />

            <NumberInput
                formik={formik}
                fieldName={'apoderadoRegisterNumber'}
                label={'Número de Registro'}
                isRequired={true}
            />

            <NumberInput
                formik={formik}
                fieldName={'apoderadoRegisterTome'}
                label={'Tomo de Registro'}
                isRequired={true}
            />

            <NumberInput
                formik={formik}
                fieldName={'apoderadoProtocol'}
                label={'Protocolo'}
                isRequired={true}
            />

            <CalendarInput
                formik={formik}
                fieldName={'apoderadoRegisterDate'}
                label={'Fecha de Registro'}
                isRequired={true}
            />

        </FormInputGroup>}

        <SectionHeading text={'Cuéntanos a qué te dedicas'} hideRequiredFieldsMessage />

        <FormInputGroup>

            <DropDownInput
                formik={formik}
                fieldName={'clienteCondition'}
                label={'Condición de cliente'}
                options={formatDropDownOptions(dropDownOptions?.data?.customerTypes)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'clientCharacteristics'}
                label={'Caracteristicas del Cliente'}
                options={formatDropDownOptions(dropDownOptions?.data?.customerFeatures)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'profesion'}
                label={'Profesión'}
                options={formatDropDownOptions(dropDownOptions?.data?.professions)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'activity'}
                label={'Actividad Específica'}
                options={formatDropDownOptions(dropDownOptions?.data?.specificActivities)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'economicActivity'}
                label={'Actividad Economica'}
                options={formatDropDownOptions(dropDownOptions?.data?.economicActivities)}
                isRequired={true}
            />


            <DropDownInput
                formik={formik}
                fieldName={'specialCategory'}
                label={'Categoria Especial'}
                options={formatDropDownOptions(dropDownOptions?.data?.specialCategories)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'academicLevel'}
                label={'Nivel Académido'}
                options={formatDropDownOptions(dropDownOptions?.data?.academicLevels)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'incomeSource'}
                label={'Fuente de Ingreso'}
                options={formatDropDownOptions(dropDownOptions?.data?.sourceOfFunds)}
                isRequired={true}
            />

        </FormInputGroup>

    
        <div className='form-actions'>

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
