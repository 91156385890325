export const PRODUCTS_LIST_CUENTA_CORRIENTE = [
    {label: 'Cuenta Corriente', value: 'Cuenta Corriente'},
    {label: 'Mi Cuenta Corriente', value: 'Mi Cuenta Corriente'},
    {label: 'Cuenta Corriente Remunerada', value: 'Cuenta Corriente Remunerada'},
    {label: 'Mi Cuenta Corriente Remunerada', value: 'Mi Cuenta Corriente Remunerada'},
    {label: 'Cuenta Corriente Clásica', value: 'Cuenta Corriente Clásica'},
    {label: 'Cuenta Corriente Emprendedora', value: 'Cuenta Corriente Emprendedora'},
];

export const PRODUCTS_LIST_CUENTA_AHORRO = [
    {label: 'Cuenta de Ahorro', value: 'Cuenta de Ahorro'},
    {label: 'Cuenta de Ahorro Infantil', value: 'Cuenta de Ahorro Infantil'},
    {label: 'Mi Cuenta de Ahorro', value: 'Mi Cuenta de Ahorro'},
];

export const PRODUCTS_LIST_CUENTA_MONEDA_EXTRANJERA = [
    {label: 'Cuenta Corriente Moneda Extranjera Euros', value: 'Cuenta Corriente Moneda Extranjera Euros'},
    {label: 'Cuenta Corriente Moneda Extranjera Dólar', value: 'Cuenta Corriente Moneda Extranjera Dólar'},
    {label: 'Mi Cuenta en Divisas Digital Dólar', value: 'Mi Cuenta en Divisas Digital Dólar'},
    {label: 'Mi Cuenta en Divisas Digital Euros', value: 'Mi Cuenta en Divisas Digital Euros'},
];