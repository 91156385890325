export const RESPONSE_CODES = {
    getDocumentInfoSuccess: 990,
}

export const IDENTIFICATION_MASK_FORMAT = '99999?999';
export const RIF_MASK_FORMAT = '99999999-9';
export const PHONE_MASK_FORMAT = '999-9999';
export const BUSINESS_PHONE_MASK_FORMAT = '99-999-9999';
export const TIN_MASK_FORMAT = '99999999?99999999';

export const CURRENCY_TYPES = [
    { label: 'Bolivares (Bs)', value: 'bolivares' },
    { label: 'Dolares (US$)', value: 'dolares' },
    { label: 'Euros (€)', value: 'euros' },
    { label: 'Pesos Colombianos (Col$)', value: 'pesocolombiano' },
    { label: 'Moneda Virtual', value: 'monedavirual' }
]