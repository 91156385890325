// Validation Schemas

import { validationSchema as PersonalInfoSchema } from "./1.PersonalInfo/ValidationSchema"
import { validationSchema as AdditionalInfoSchema } from "./2.AdditionalInfo/ValidationSchema"
import { validationSchema as JobInfoSchema } from "./3.JobInfo/ValidationSchema"
import { validationSchema as ProductInfoSchema } from "./4.ProductInfo/ValidationSchema"

// Modules

export const FORM_MODULES = [
    {
      name: 'Primero tus datos básicos, porque lo importante es conocernos',
      mobileName: 'Datos básicos',
      steps: PersonalInfoSchema.length,
      schema: PersonalInfoSchema,
    },
    {
      name: 'Conozcámonos un poco más',
      mobileName: 'Cuéntanos más',
      steps: AdditionalInfoSchema.length,
      schema: AdditionalInfoSchema,
    },
    {
      name: '¿Posees empleo? Cuéntanos más',
      mobileName: '¿Posees empleo?',
      steps: JobInfoSchema.length,
      schema: JobInfoSchema,
    },
    {
      name: 'Información del producto o servicio',
      mobileName: 'Info producto',
      steps: ProductInfoSchema.length,
      schema: ProductInfoSchema,
    },
]