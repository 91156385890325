import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import TextInput from '../../../../UI/FormInput/TextInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import MaskInput from '../../../../UI/FormInput/MaskInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';

// Utilities

import { formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { PHONE_MASK_FORMAT } from '../../../../../config/constants';

export default function ContactInfo({formik, formStep, setFormStep}) {
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
    const dispatch = useDispatch();

    return (
    <>
        <SectionHeading text={'Introduce tus datos de contacto, porque la vía más expedita es conectarnos vía digital o telefónica'} />

        <FormInputGroup>
        
            <TextInput
            formik={formik}
            fieldName={'email'}
            label={'Correo electrónico'}
            maxLength={80}
            isRequired={true}
            />
        
        </FormInputGroup>

        <SectionHeading isSubHeading text={'Teléfono 1'} />

        <div className='form-field-phone-group'>

            <DropDownInput
                formik={formik}
                fieldName={'phoneType1'}
                label={'Tipo de Teléfono'}
                options={formatDropDownOptions(dropDownOptions?.data?.phoneTypes)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'phoneAreaCode1'}
                label={'Código de Área'}
                options={formatDropDownOptions(dropDownOptions?.data?.areaCodes)}
                isRequired={true}
            />

            <MaskInput
                formik={formik}
                fieldName={'phone1'}
                label={'Número de teléfono'}
                mask={PHONE_MASK_FORMAT}
                isRequired={true}
            />

        </div>

        <SectionHeading isSubHeading text={'Teléfono 2'} />

        <div className='form-field-phone-group'>

            <DropDownInput
                formik={formik}
                fieldName={'phoneType2'}
                label={'Tipo de Teléfono'}
                options={formatDropDownOptions(dropDownOptions?.data?.phoneTypes)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'phoneAreaCode2'}
                label={'Código de Área'}
                options={formatDropDownOptions(dropDownOptions?.data?.areaCodes)}
                isRequired={true}
            />

            <MaskInput
                formik={formik}
                fieldName={'phone2'}
                label={'Número de teléfono'}
                mask={PHONE_MASK_FORMAT}
                isRequired={true}
            />

        </div>
            
        <div className='form-actions'>

            <Button
                action={() => dispatch(setFormStep(formStep - 1))}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
