import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetStatesByCountry() {

    // Get the States by Country

    const handleGetStatesByCountry = useCallback(async (country, setLoading, setError, setStates) => {
        setLoading(true);
        setError(null);

        try {
            const statesRequest = await OptionsService.getStatesByCountry(country);

            setLoading(false);
            setStates(statesRequest?.data?.data);
    

        } catch(error) {

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetStatesByCountry,
    }
}
