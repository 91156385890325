import React from 'react'
import { RadioButton } from 'primereact/radiobutton';

import InputWrapper from './InputWrapper';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function RadioInput(props) {
    const {
        formik,
        fieldName,
        options,
        extraLabel,
        additionalFunctions,
    } = props;

    return (
        <InputWrapper {...props}>
            
            {extraLabel}
            
            <div className="radio-input-wrapper">

                {options.map((option, i) =>
                    <span key={i} className='radio-input'>
                        <RadioButton
                            key={i}
                            name={fieldName}
                            inputId={option.value}
                            value={option.value}
                            checked={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) === option.value : formik.values[fieldName] === option.value}
                            onChange={(e) => { formik.setFieldValue(fieldName, e.target.value); if (additionalFunctions) { additionalFunctions() } }}
                            onBlur={formik.handleBlur(fieldName)}
                            label={option.label}
                        />
                        <label htmlFor={option.label}>
                            {option.label}
                        </label>
                    </span>
                )}

            </div>

        </InputWrapper>
    )
}

