import React from 'react'

import { useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import Button from '../../../../UI/Button/Button';


export default function IncomeDeclaration({formik, formStep, setFormStep}) {
    const dispatch = useDispatch();

    return (
    <>
        <SectionHeading text={'Declaración jurada de no poseer cuenta en ninguna institución bancaria'} />

        {formik.values?.hasBankReference === 'si' &&
        <RadioInput
            formik={formik}
            fieldName={'hadBankAccount'}
            label={'¿Posee cuenta bancaria?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />}

        {formik.values?.hasBankReference === 'no' &&
        <div className='inline-text-box'>
            <p>Yo, <strong>{formik.values?.name} {formik.values?.firstlastname} {formik.values?.secondlastname}</strong>  , de nacionalidad: <strong>{formik.values?.nationality}</strong>, titular del documento de identidad o pasaporte (en caso de extranjero) Nro. <strong>{formik.values?.documentId}</strong>, mediante la presente Declaro bajo juramento, que recibo: honorarios/bonificaciones/comisiones/ otros ingresos, desde el : <strong>__/__/____</strong>, y por ello obtengo ingresos mensuales por la cantidad total en: (Selección de moneda) de <strong>___________</strong>(Cantidad escrita)<strong>_____________</strong>. (Selección de moneda) <strong>____</strong>(Cantidad en números)<strong>_____</strong>.</p>

            <p>De conformidad con la normativa vigente sobre la Administración de Riesgos de Legitimación de Capitales, Financiamiento al Terrorismo y Financiamiento a la Proliferación de Armas de Destrucción Masiva, he presentado la anterior información a BANCARIBE, C.A. Banco Universal, la cual corresponde a las actividades económicas legítimas y licitas que desarrollo, y me comprometo a mantener a su disposición la documentación que le sirve de soporte en caso que me sea requerida su exhibición o consignación.</p>

            <p>En la ciudad de <strong>{formik.values?.birthcity}</strong>, a los <strong>______</strong> del mes <strong>_____</strong> del año <strong>____</strong> <br/>
            Nombres y Apellidos: <strong>{formik.values?.name} {formik.values?.firstlastname} {formik.values?.secondlastname}</strong><br/>
            Número de documento de Identidad o Pasaporte: <strong>{formik.values?.documentId}</strong>.<br/>
            Firma: <strong>__________________</strong></p>
        </div>}

        {formik.values?.hasBankReference === 'no' &&
        <RadioInput
            formik={formik}
            fieldName={'declaration'}
            label={'Si, he leido y acepto esta declaración'}
            options={[{label: 'Sí', value: 'si'}]}
            isGroup={true}
            isRequired={true}
        />}

        <div className='form-actions'>

            <Button
                action={() => dispatch(setFormStep(formStep - 1))}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
