import React from 'react'

import { useSelector } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import CurrencyInput from '../../../../UI/FormInput/CurrencyInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import NumberInput from '../../../../UI/FormInput/NumberInput';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import CheckBoxInput from '../../../../UI/FormInput/CheckBoxInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';

// Utilities

import { formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { CURRENCY_TYPES } from '../../../../../config/constants';

export default function ProductCurrencies({formik, goToPreviousModule}) {
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);

    return (
    <>
        <SectionHeading text={'¿Como sera la movilización de tus productos por tipo de moneda?'} />

        {/* CURRENCY TYPES */}

        <CheckBoxInput
            formik={formik}
            fieldName={'productCurrency'}
            label={'Monedas'}
            options={CURRENCY_TYPES}
            isGroup={true}
            isRequired={true}
            additionalFunctions={
                () => {
                    if (!formik.values.productCurrency?.includes('bolivares')) {
                        formik.setFieldValue('fundsBolivares', {});
                    } else if (!formik.values.productCurrency?.includes('dolares')) {
                        formik.setFieldValue('fundsDolares', {});
                    } else if (!formik.values.productCurrency?.includes('euros')) {
                        formik.setFieldValue('fundsEuros', {});
                    } else if (!formik.values.productCurrency?.includes('pesocolombiano')) {
                        formik.setFieldValue('fundsPesosColombianos', {});
                    } else if (!formik.values.productCurrency?.includes('monedavirual')) {
                        formik.setFieldValue('fundsMonedaVirtual', {});
                    }
                }
            }
        />

        {/* CURRENCY TYPES BOLIVARES */}

        {formik.values.productCurrency?.includes('bolivares') &&
        <>
            <SectionHeading text={'Información sobre movilización de fondos en Bolivares (Bs)'} isSubHeading />

            <FormInputGroup>

                <CurrencyInput
                    formik={formik}
                    fieldName={'fundsBolivares.productFundsMonthlyAverage'}
                    label={'Monto promedio mensual'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsBolivares.productNumberOfDeposits'}
                    label={'Cantidad de depósitos a realizar'}
                    isRequired={true}
                    max={999}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsBolivares.productNumberOfWithdrawals'}
                    label={'Cantidad de retiros a realizar'}
                    isRequired={true}
                    max={999}
                />

            </FormInputGroup>
        </>}

        {/* CURRENCY TYPES DOLARES */}

        {formik.values.productCurrency?.includes('dolares') &&
        <>
            <SectionHeading text={'Información sobre movilización de fondos en Dolares (US$)'} isSubHeading />

            <FormInputGroup>

                <CurrencyInput
                    formik={formik}
                    fieldName={'fundsDolares.productFundsMonthlyAverage'}
                    label={'Monto promedio mensual'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsDolares.productNumberOfDeposits'}
                    label={'Cantidad de depósitos a realizar'}
                    isRequired={true}
                    max={999}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsDolares.productNumberOfWithdrawals'}
                    label={'Cantidad de retiros a realizar'}
                    isRequired={true}
                    max={999}
                />

            </FormInputGroup>
        </>}

        {/* CURRENCY TYPES EUROS */}

        {formik.values.productCurrency?.includes('euros') &&
        <>
            <SectionHeading text={'Información sobre movilización de fondos en Euros (€)'} isSubHeading />

            <FormInputGroup>

                <CurrencyInput
                    formik={formik}
                    fieldName={'fundsEuros.productFundsMonthlyAverage'}
                    label={'Monto promedio mensual'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsEuros.productNumberOfDeposits'}
                    label={'Cantidad de depósitos a realizar'}
                    isRequired={true}
                    max={999}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsEuros.productNumberOfWithdrawals'}
                    label={'Cantidad de retiros a realizar'}
                    isRequired={true}
                    max={999}
                />

            </FormInputGroup>
        </>}

        {formik.values.productCurrency?.includes('pesocolombiano') &&
        <>
            <SectionHeading text={'Información sobre movilización de fondos en Pesos Colombianos (Col$)'} isSubHeading />

            <FormInputGroup>

                <CurrencyInput
                    formik={formik}
                    fieldName={'fundsPesosColombianos.productFundsMonthlyAverage'}
                    label={'Monto promedio mensual'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsPesosColombianos.productNumberOfDeposits'}
                    label={'Cantidad de depósitos a realizar'}
                    isRequired={true}
                    max={999}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsPesosColombianos.productNumberOfWithdrawals'}
                    label={'Cantidad de retiros a realizar'}
                    isRequired={true}
                    max={999}
                />

            </FormInputGroup>
        </>}

        {formik.values.productCurrency?.includes('monedavirual') &&
        <>
            <SectionHeading text={'Información sobre movilización de fondos en Moneda Virtual'} isSubHeading />

            <FormInputGroup>

                <DropDownInput
                    formik={formik}
                    fieldName={`fundsMonedaVirtual.productCurrencyType`}
                    label={'Tipo moneda virtual'}
                    options={formatDropDownOptions(dropDownOptions?.data?.virtualCurrencies)}
                    isRequired={true}
                />

                <CurrencyInput
                    formik={formik}
                    fieldName={'fundsMonedaVirtual.productFundsMonthlyAverage'}
                    label={'Monto promedio mensual'}
                    isRequired={true}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsMonedaVirtual.productNumberOfDeposits'}
                    label={'Cantidad de depósitos a realizar'}
                    isRequired={true}
                    max={999}
                />

                <NumberInput
                    formik={formik}
                    fieldName={'fundsMonedaVirtual.productNumberOfWithdrawals'}
                    label={'Cantidad de retiros a realizar'}
                    isRequired={true}
                    max={999}
                />

            </FormInputGroup>
        </>}

        <SectionHeading text={'Datos de la transferencia'} isSubHeading />

        <RadioInput
            formik={formik}
            fieldName={'productHasInternationalFunds'}
            label={'¿Recibes o Envías fondos desde/hacia el exterior?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values.productHasInternationalFunds === 'si' &&
        <>
            <FormInputGroup>
                <DropDownInput
                    formik={formik}
                    fieldName={'productFundsOriginCountry'}
                    label={'País de Origen'}
                    options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={'productFundsDestinyCountry'}
                    label={'País Destino'}
                    options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                />
            </FormInputGroup>
        </>}

        <FormInputGroup>

            <DropDownInput
                formik={formik}
                fieldName={'productMotive'}
                label={'Motivo de la apertura'}
                options={formatDropDownOptions(dropDownOptions?.data?.reasonForOpenings)}
            />

            <DropDownInput
                formik={formik}
                fieldName={'productFundsOrigin'}
                label={'Origen de los fondos '}
                options={formatDropDownOptions(dropDownOptions?.data?.sourceOfFunds)}
            />

            <DropDownInput
                formik={formik}
                fieldName={'productFundsDestiny'}
                label={'Destino de los fondos'}
                options={formatDropDownOptions(dropDownOptions?.data?.destinationOfFunds)}
            />

        </FormInputGroup>

        <SectionHeading text={'Selecciona la oficina de tu preferencia'} isSubHeading />

        <FormInputGroup>
            <DropDownInput
                formik={formik}
                fieldName={'productOfficeState'}
                label={'Estado'}
                options={formatDropDownOptions(dropDownOptions?.data?.states)}
            />

            <DropDownInput
                formik={formik}
                fieldName={'productOfficeMunicipalty'}
                label={'Municipio'}
                options={formatDropDownOptions(dropDownOptions?.data?.municipalities)}
            />

            <DropDownInput
                formik={formik}
                fieldName={'productOfficeName'}
                label={'Officina'}
                options={formatDropDownOptions(dropDownOptions?.data?.offices)}
            />
        </FormInputGroup>

        <div className='form-actions'>

        <Button
            action={() => goToPreviousModule(2)}
            label={'Regresa'}
            // disabled={state?.isLoading}
            secondaryStyle={true}
        />

        <Button
            type="submit"
            action={formik.handleSubmit}
            label={'Continua'}
            // disabled={!(formik.isValid && formik.dirty)}
            // isLoading={state?.isLoading}
        />

        </div>
    </>
  )
}
