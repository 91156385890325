import {combineReducers} from 'redux';

import authReducer from './auth/auth.reducers';
import optionsReducer from './options/options.reducers';
import vuReducer from './vu/vu.reducers';
import formsReducer from './forms/forms.reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  options: optionsReducer,
  vu: vuReducer,
  forms: formsReducer
});

export default rootReducer;
