import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetMunicipalitiesByCity() {

    // Get the Municipalities by City

    const handleGetMunicipalitiesByCity = useCallback(async (city, setLoading, setError, setMunicipalities) => {
        setLoading(true);
        setError(null);

        try {

            const municipalitiesRequest = await OptionsService.getMunicipalitiesByCity(city);

            setLoading(false);
            setMunicipalities(municipalitiesRequest?.data?.data);
    

        } catch(error) {

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetMunicipalitiesByCity,
    }
}
