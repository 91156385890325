import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'

// Services

import { vuasSendOtp, vuasValidateOtp, cleanVuasStore } from '../../../store/vu/vu.actions';

// Components

import SectionHeading from '../../UI/SectionHeading/SectionHeading';
import CodeInput from '../../UI/FormInput/CodeInput';
import Button from '../../UI/Button/Button';

// Validation

import { validationSchema } from './ValidationSchema';

export default function AccessCodeForm({setShowForm, userName, userEmail}) {
  const [count, setCount] = useState(180);
  const navigate = useNavigate();
  const sendOtp = useSelector((state) => state.vu.sendOtp);
  const validateOtp = useSelector((state) => state.vu.validateOtp);
  const dispatch = useDispatch();

  // Setup form with formik

  const formik = useFormik({
    initialValues: { username: userName, otp: '' },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      handleVuasValidateOtp(values, actions);
    },
  });

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);


  // VUAS Validate OTP

  const handleVuasValidateOtp = async (values, actions) => {
    dispatch(vuasValidateOtp(values));   
  }

  // VUAS Send OTP

  const handleVuasSendOtp = async () => {
    const data = {
      username: userName,
      email: userEmail,
    }

    dispatch(vuasSendOtp(data));
  }

  useEffect(() => {
    if (validateOtp.otpValid) {
      dispatch(cleanVuasStore());
      navigate('/tipocuenta');
    }
  }, [validateOtp, navigate, dispatch])

  return (  
      <form onSubmit={formik.handleSubmit}>

        {/* Form Fields */}

        <SectionHeading text={'Clave dinámica'} headingAlt={true} />

        <p className='form-paragraph'>Ingresa el código de seguridad que enviamos a tu correo electrónico.</p>

        {/* Form Error Message */}

        {(validateOtp.error || sendOtp.error) && <p className="form-error-message">{validateOtp.error || sendOtp.error}</p>}

        <p className='form-code-expiration'>Tu clave expira en <strong>{count}</strong> segundos</p>

        <CodeInput
          formik={formik}
          fieldName={'otp'}
        />

        <button
          onClick={()=> handleVuasSendOtp()}
          type='button'
          className='btn-link btn-link-alt'
          disabled={sendOtp.isLoading}
        >
          {sendOtp.isLoading &&
          <span>Reenviando la clave...</span>}

          {!sendOtp.isLoading &&
          <span>¿No recibiste la clave? <strong>Reenviar</strong></span>}
        </button>

        <div className='form-actions'>

          <Button
              type="submit"
              action={formik.handleSubmit}
              label={'Enviar'}
              additionalStyles={{width: '100%', margin: 0, marginBottom: '1rem'}}
              disabled={!(formik.isValid && formik.dirty)}
              isLoading={validateOtp.isLoading}
          />

          <Button
              type="button"
              action={()=> setShowForm('login')}
              label={'Regresa'}
              additionalStyles={{width: '100%', margin: 0}}
              secondaryStyle={true}
          />

        </div>

      </form>
  )
}