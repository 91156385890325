import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetSectorsByState() {

    // Get the Sectors by State

    const handleGetSectorsByState = useCallback(async (state, setLoading, setError, setSectors) => {
        setLoading(true);
        setError(null);

        try {
            const sectorsRequest = await OptionsService.getSectorsByState(state);

            setLoading(false);
            setSectors(sectorsRequest?.data?.data);

        } catch(error) {    

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetSectorsByState,
    }
}
