import React from 'react'

import './Styles.scss';

export default function SectionHeading({text, isSubHeading, hideRequiredFieldsMessage, headingAlt}) {
  return (
    <div className={headingAlt ? 'section-heading section-heading-alt' : 'section-heading'}>
        {isSubHeading ? <h3>{text}</h3> : <h2>{text}</h2>}

        {!hideRequiredFieldsMessage && !isSubHeading && !headingAlt &&
        <p>Campos obligatorios <span>*</span></p>}
    </div>
  )
}
