
// Set Nested Field Name

export const setNestedFieldName = (fieldName, formValues) => {
    return fieldName.split('.').reduce((o,i)=> o[i], formValues);
}

// Truncate a string to a given lenght

export const truncateString = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};

// Add Item to Array

export const addItemToArray = (formik, fieldName, item) => {
    const items = fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName];
    const fieldData = [...items];

    fieldData.push(item ? item : {});

    formik.setFieldValue(fieldName, fieldData);
}

// Remove Item from Array

export const removeItemFromArray = (formik, fieldName) => {
    const items = fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName];
    const fieldData = [...items];

    fieldData.pop();

    formik.setFieldValue(fieldName, fieldData);
}

// Format dropdown options

export const formatDropDownOptions = (options) => {
    const items = options ? options?.map(option => ({
        value: option.value, 
        label: option.name?.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
    })) : [];

    return items;
}