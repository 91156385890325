
import React from 'react'

// Styles

import './Styles.scss';

export default function Alert({text}) {
  return (
    <p className='alert'>
        {text}
    </p>
  )
}