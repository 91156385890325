import React from 'react'

// Module Steps

import AboutYou from './Steps/1.AboutYou';
import KeepInTouch from './Steps/2.KeepInTouch';
import ContactInfo from './Steps/3.ContactInfo.js';

export default function PersonalInfo({formik, formStep, setFormStep}) {
  return (
    <>
        {/* STEP 1: We want to know more about you */}

        {formStep === 0 &&
        <AboutYou
          formik={formik}
        />}

        {/* STEP 2: Your room address is essential to keep in touch */}

        {formStep === 1 &&
        <KeepInTouch
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
        />}

        {/* STEP 3: Introduce your contact info */}

        {formStep === 2 &&
        <ContactInfo
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
        />}
    </>
  )
}
