
import { VU_API, VU_VUAS_API } from "../config/api";

export default class VUService {

    // VUAS Send OTP ====================================================================================================

    /**
     * @param data {
            @string username,
            @string email,
        }
    * @returns True or false if the email with the otp was sent
    */

    static vuasSendOtp(data) {
        const url = `/vuas-send-otp`;

        return VU_VUAS_API.post(url, data);
    }

    
    // VUAS Validate OTP ====================================================================================================

    /**
     * @param data {
            @string username,
            @string otp,
        }
    * @returns True or false if the otp was valid
    */

    static vuasValidateOtp(data) {
        const url = `/vuas-validate`;

        return VU_VUAS_API.post(url, data);
    }


    // Get Document Info ====================================================================================================

    /**
     * @param data {
            @string operationId,
            @string operationGuid,
            @string userName,
        }
    * @returns The document info from the operation
    */

    static getDocumentInfo(data) {
        const url = `/getDocumentInformation`;

        return VU_API.post(url, data);
    }
    
}
