import React from 'react'

import { useSelector } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import TextInput from '../../../../UI/FormInput/TextInput';
import MaskInput from '../../../../UI/FormInput/MaskInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import CalendarInput from '../../../../UI/FormInput/CalendarInput';
import RadioInput from '../../../../UI/FormInput/RadioInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';

// Utilities

import { formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { IDENTIFICATION_MASK_FORMAT, PHONE_MASK_FORMAT, TIN_MASK_FORMAT } from '../../../../../config/constants';

export default function KnowMore({formik, goToPreviousModule}) {
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);

    return (
    <>
        <SectionHeading text={'Conozcámonos un poco más'} />

        <p>¿Usted ha desempeñado alguno de los cargos listados durante los últimos 10 años?</p>

        <FormInputGroup>

            <DropDownInput
                formik={formik}
                fieldName={'occupation'}
                label={'Cargos'}
                options={formatDropDownOptions(dropDownOptions?.data?.positions)}
                isRequired={true}
            />

        </FormInputGroup>

        {formik.values.occupation !== '0' &&
        <FormInputGroup>

            <TextInput
                formik={formik}
                fieldName={'occupationDesc'}
                label={'Nombre del ente de adscripción'}
                maxLength={80}
                isRequired={true}
            />
            
            <DropDownInput
                formik={formik}
                fieldName={'occupationCountry'}
                label={'Pais'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

        </FormInputGroup>}

        <RadioInput
            formik={formik}
            fieldName={'political'}
            label={'¿Usted tiene parentesco o es asociado con alguien que haya desempeñado un alto cargo político en los últimos diez años?'}
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values?.political === 'si' &&
        <FormInputGroup>

            <DropDownInput
                formik={formik}
                fieldName={'politicalRelation'}
                label={'Tipo de relación'}
                options={formatDropDownOptions(dropDownOptions?.data?.relationshipTypes)}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'politicalPersonName'}
                label={'Nombre del la persona'}
                maxLength={80}
                isRequired={true}
            />

            <MaskInput
                formik={formik}
                fieldName={`politicalDocumentId`}
                label={'Documento de Identidad'}
                mask={IDENTIFICATION_MASK_FORMAT}
                isRequired={true}
            />

            <CalendarInput
                formik={formik}
                fieldName={`politicalBirthdate`}
                label={'Fecha de Nacimiento'}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'ocpoliticalGender'}
                label={'Género'}
                options={formatDropDownOptions(dropDownOptions?.data?.genders)}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'politicalBirthPlace'}
                label={'Lugar de nacimiento'}
                maxLength={80}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'politicalNationality'}
                label={'Nacionalidad'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'politicalOtherNationality'}
                label={'Otra Nacionalidad'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'politicalProfession'}
                label={'Profesión'}
                options={formatDropDownOptions(dropDownOptions?.data?.professions)}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'politicalFiscalDir'}
                label={'Dir. Fiscal'}
                maxLength={80}
                isRequired={true}
            />

            <MaskInput
                formik={formik}
                fieldName={`politicalPhone`}
                label={'Teléfono'}
                mask={PHONE_MASK_FORMAT}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'politicalDesc'}
                label={'Nombre del ente de adscripción'}
                maxLength={80}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'politicalnName'}
                label={'Nombre del cargo'}
                options={formatDropDownOptions(dropDownOptions?.data?.positions)}
                isRequired={true}
            />
            
            <DropDownInput
                formik={formik}
                fieldName={'politicalCountry'}
                label={'Pais'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

        </FormInputGroup>}

        <RadioInput
            formik={formik}
            fieldName={'usRelationships'}
            label={'¿Posee alguna de las siguientes relaciones con los EEUU?'}
            extraLabel={
                <p className='extra-label'>
                - Residencia<br/>
                - Nacionalidad<br/>
                - Ciudadano<br/>
                - ¿Posee una dirección de correspondencia en los EEUU?<br/>
                - ¿Cuenta con apoderados en los estados unidos ¿que puedan obligarlo o contratar por usted?<br/>
                - Posee Número de identificación del contribuyente (TIN)<br/>
                </p>
            }
            options={[{label: 'Sí', value: 'si'},{label: 'No', value: 'no'},]}
            isGroup={true}
            isRequired={true}
        />

        {formik.values?.usRelationships === 'si' &&
        <FormInputGroup>

            <MaskInput
                formik={formik}
                fieldName={'usRelationshipsTIN'}
                label={'Coloque el número de identificación TIN'}
                mask={TIN_MASK_FORMAT}
                isRequired={true}
                helpText={'Recuerda que por tu relación con los EEUU deberás tener a la disposición del banco unos de los siguientes formatos, según tu condición: formas W-8BEN-E, W8 y/o W9'}
            />

        </FormInputGroup>}

        <div className='form-actions'>

            <Button
                action={() => goToPreviousModule(0)}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
