import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  sendOtp: {},
  validateOtp: {},
  getDocumentInfo: {}
};

export const vuSlice = createSlice({
  name: 'vu',
  initialState,
  reducers: {

    // VUAS Send OTP

    setSendOtpLoading: (state) => {
      state.sendOtp = {
        isLoading: true,
      };
    },
    setSendOtpSuccess: (state, action) => {
      state.sendOtp = {
        otpSent: action.payload
      };
    },
    setSendOtpFailure: (state, action) => {
      state.sendOtp = {
        error: action.payload,
      }
    },
    cleanupSendOtp: (state, action) => {
      state.sendOtp = {}
    },

    // VUAS Validate OTP

    setValidateOtpLoading: (state) => {
      state.validateOtp = {
        isLoading: true,
      };
    },
    setValidateOtpSuccess: (state, action) => {
      state.validateOtp = {
        otpValid: action.payload
      };
    },
    setValidateOtpFailure: (state, action) => {
      state.validateOtp = {
        error: action.payload,
      }
    },
    cleanupValidateOtp: (state, action) => {
      state.validateOtp = {}
    },

    // Get Document Info

    setGetDocumentInfoLoading: (state) => {
      state.getDocumentInfo = {
        isLoading: true,
      };
    },
    setGetDocumentInfoSuccess: (state, action) => {
      state.getDocumentInfo = {
        otpValid: action.payload
      };
    },
    setGetDocumentInfoFailure: (state, action) => {
      state.getDocumentInfo = {
        error: action.payload,
      }
    },
    cleanupGetDocumentInfo: (state, action) => {
      state.getDocumentInfo = {}
    },

  },
})

export const {
  setSendOtpLoading, setSendOtpSuccess, setSendOtpFailure, cleanupSendOtp,
  setValidateOtpLoading, setValidateOtpSuccess, setValidateOtpFailure, cleanupValidateOtp,
  setGetDocumentInfoLoading, setGetDocumentInfoSuccess, setGetDocumentInfoFailure, cleanupGetDocumentInfo,
} = vuSlice.actions

export default vuSlice.reducer;