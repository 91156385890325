import React, {useEffect} from 'react';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'

// Services

import { vuasSendOtp, cleanVuasStore } from '../../../store/vu/vu.actions';

// Components

import SectionHeading from '../../UI/SectionHeading/SectionHeading';
import TextInput from '../../UI/FormInput/TextInput';
import NumberInput from '../../UI/FormInput/NumberInput';
import Button from '../../UI/Button/Button';

// Validation

import { initialValues } from './InitialValues';
import { validationSchema } from './ValidationSchema';

export default function RegisterForm({setShowForm, setUsername, setUserEmail}) {
  const sendOtp = useSelector((state) => state.vu.sendOtp);
  const dispatch = useDispatch();

  // Setup form with formik

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      handleVuasSendOtp(values, actions);
    },
  });


  // VUAS Send OTP

  const handleVuasSendOtp = async (values, actions) => {    
    dispatch(vuasSendOtp(values));
  }

  useEffect(() => {
    if (sendOtp.otpSent) {
      setUsername(formik.values.username);
      setUserEmail(formik.values.email);
      setShowForm('code');
    }
  }, [sendOtp, formik.values, setShowForm, setUsername, setUserEmail])
  
  useEffect(() => {
    dispatch(cleanVuasStore())
  }, [dispatch])

  return (
      <form onSubmit={formik.handleSubmit}>

        {/* Form Fields */}

        <SectionHeading text={'Registro'} headingAlt={true} />

        <p className='form-paragraph'>Para un registro exitoso necesitamos conocerte un poco más. Sigue estos simples pasos, te enviaremos un código de seguridad a tu correo electrónico y te estaremos acompañando en el camino.</p>

        {/* Form Error Message */}

        {sendOtp.error && <p className="form-error-message">{sendOtp.error}</p>}

        <TextInput
          formik={formik}
          fieldName={'username'}
          label={'Nombres'}
          maxLength={80}
          isRequired={true}
        />

        <NumberInput
          formik={formik}
          fieldName={'age'}
          label={'Edad'}
          isRequired={true}
          max={120}
        />

        <TextInput
          formik={formik}
          fieldName={'email'}
          label={'Correo'}
          isRequired={true}
        />

        <div className='form-actions'>

          <Button
              type="submit"
              action={formik.handleSubmit}
              label={'Registrarme'}
              additionalStyles={{width: '100%', margin: 0}}
              disabled={!(formik.isValid && formik.dirty)}
              isLoading={sendOtp.isLoading}
          />

          <button
            onClick={()=> setShowForm('login')}
            type='button'
            className='btn-link'
          >
            ¿Ya eres cliente? <strong>Inicia sesión</strong>
          </button>

        </div>

      </form>
  )
}