import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetParishesByMunicipality() {

    // Get the Parishes by Municipality

    const handleGetParishesByMunicipality = useCallback(async (municipality, setLoading, setError, setParishes) => {
        setLoading(true);
        setError(null);

        try {

            const parishesRequest = await OptionsService.getParishiesByMunicipality(municipality);

            setLoading(false);
            setParishes(parishesRequest?.data?.data);

        } catch(error) {

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetParishesByMunicipality,
    }
}
