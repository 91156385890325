import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetCitiesByCountry() {

    // Get the Cities by Country

    const handleGetCitiesByCountry = useCallback(async (country, setLoading, setError, setCities) => {
        setLoading(true);
        setError(null);

        try {
            const citiesRequest = await OptionsService.getCitiesByCountry(country);

            setLoading(false);
            setCities(citiesRequest?.data?.data);

        } catch(error) {

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetCitiesByCountry,
    }
}
