import React from 'react'

// Components

import UserDetailsForm from '../../components/forms/UserDetailsForm/UserDetailsForm'

export default function Register() {  
  return (
    <UserDetailsForm />
  )
}
