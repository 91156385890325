import axios from 'axios';

// Setup Axios Instance

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});


// Setup VU VUAS Axios Instance

export const VU_VUAS_API = axios.create({
    baseURL: process.env.REACT_APP_VU_VUAS_API_URL,
});

// Setup VU Axios Instance

export const VU_API = axios.create({
    baseURL: process.env.REACT_APP_VU_API_URL,
});


// Automagically add the private key for the document type to each request

VU_API.interceptors.request.use(config => {
    const apiKey = process.env.REACT_APP_VU_API_KEY;
    if (apiKey) {
        config.headers['x-access-apikey-private'] = apiKey;
    }
    return config;
});

export default VU_API;