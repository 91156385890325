import React from 'react'
import { InputNumber } from "primereact/inputnumber";

import InputWrapper from './InputWrapper';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function NumberInput(props) {
    const {
        formik,
        fieldName,
        isRequired,
        placeholder,
        max,
        disabled
    } = props;

    return (
        <InputWrapper {...props}>

            <InputNumber 
                id={fieldName}
                name={fieldName}
                value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                onChange={(e) => formik.setFieldValue(fieldName, e.value)}
                onBlur={formik.handleBlur(fieldName)}
                placeholder={placeholder}
                className={`input-field ${formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}`}
                required={isRequired}
                max={max}
                disabled={disabled}
            />

        </InputWrapper>
    )
}

