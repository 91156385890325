import React from 'react'

// Module Steps

import KnowMore from './Steps/1.KnowMore';
import BankReferences from './Steps/2.BankReferences';
import IncomeDeclaration from './Steps/3.IncomeDeclaration';
import PersonalReferences from './Steps/4.PersonalReferences';

export default function AdditionalInfo({formik, formStep, setFormStep, goToPreviousModule}) {
  return (
    <>
        {/* STEP 1: Let's know each other more */}

        {formStep === 0 &&
        <KnowMore
          formik={formik}
          goToPreviousModule={goToPreviousModule}
        />}

        {/* STEP 2: Bank references */}

        {formStep === 1 &&
        <BankReferences 
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
        />}

        {/* STEP 3: Income Declaration */}

        {formStep === 2 &&
        <IncomeDeclaration
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
        />}

        {/* STEP 3: Personal references */}

        {formStep === 3 &&
        <PersonalReferences
          formik={formik}
          formStep={formStep}
          setFormStep={setFormStep}
        />}
    </>
  )
}
