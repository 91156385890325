import React from 'react'

import { useDispatch } from 'react-redux'

// Hooks

import GetWindowSize from '../../../../hooks/GetWindowSize';

// Components

import Button from '../../../UI/Button/Button';

// Images

import introPerson from './Images/img-intro-person.png';

// Styles

import './Styles.scss';

export default function FormModuleIntro({modules, currentModule, handleSetShowModuleIntro}) {
    const { windowSize } = GetWindowSize();
    const dispatch = useDispatch();

    return (
        <div className='form-module-intro'>
            <div className='container'>

                {windowSize?.innerWidth >= 769 ? (
                    <div className='form-module-intro-wrapper'>

                        <div className='form-module-intro-image'>
                            <img src={introPerson} alt="" />
                        </div>

                        <div className='form-module-intro-info'>
                            <p>{currentModule + 1}</p>
                            <h2>{modules[currentModule]?.name}</h2>
                            <Button
                                type="button"
                                action={() => dispatch(handleSetShowModuleIntro(false))}
                                label={'Comienza'}
                                additionalStyles={{width: '50%', fontSize: '1.25rem', margin: 0}}
                            />
                        </div>

                    </div>
                ) : (
                    <div className='form-module-intro-wrapper'>

                        <div className='form-module-intro-info'>
                            <p>{currentModule + 1}</p>
                            <h2>{modules[currentModule]?.name}</h2>
                            <Button
                                type="button"
                                action={() => dispatch(handleSetShowModuleIntro(false))}
                                label={'Comienza'}
                                additionalStyles={{float: 'right', margin: 0}}
                            />
                        </div>

                        <div className='form-module-intro-image'>
                            <img src={introPerson} alt="" />
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}