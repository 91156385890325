import React from 'react'

// Images

import icnId from './Images/icn-id.png';
import icnInfo from './Images/icn-info.png';
import icnContract from './Images/icn-contract.png';
import icnPhotos from './Images/icn-photos.png';

// Styles

import './Styles.scss';

export default function WelcomeCard() {
  return (
    <div className="user-access-welcome">
        <h1>Hola ¡Bienvenido!</h1>
        <h2>¿Qué vas a necesitar para que tu proceso sea exitoso?</h2>

        <WelcomeInstruction
            icon={icnId}
            title={'Identificarte'}
            message={'Tu documento de identidad y el Registro de Información Fiscal (RIF) en formato PDF o JPG.'}
        />

        <WelcomeInstruction
            icon={icnInfo}
            title={'Más sobre ti'}
            message={'Es importante que tengas a la mano una referencia Bancaria y/o personal en formato PDF o JPG.'}
        />

        <WelcomeInstruction
            icon={icnContract}
            title={'Contrato'}
            message={'Leer y aceptar los términos y condiciones.'}
        />

        <WelcomeInstruction
            icon={icnPhotos}
            title={'Fotos'}
            message={'Es necesario contar con una cámara​.'}
        />

      </div>
  )
}

const WelcomeInstruction = ({icon, title, message}) => (
    <div className='welcome-instruction'>
        <div className='welcome-instruction-title'>
            <div>
                <img src={icon} alt={title} />
            </div>
            <p>{title}</p>
        </div>
        <p className='welcome-instruction-message'>{message}</p>
    </div>
);