import React, {useState} from 'react'
// import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

// Components

import Button from '../../components/UI/Button/Button';

// Images

import icnAccountType from './Images/icn-account-type.png';

// Data

import { PRODUCTS_LIST_CUENTA_CORRIENTE, PRODUCTS_LIST_CUENTA_AHORRO, PRODUCTS_LIST_CUENTA_MONEDA_EXTRANJERA } from './ProductsList';

// Styles

import './Styles.scss';

export default function AccountType() {
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  // const navigate = useNavigate();

  const handleAccountTypeSelection = (type) => {
    let accountValues = [...accountTypes];
    if (!accountValues.includes(type)) {
      accountValues.push(type);
    } else {
      const index = accountValues.indexOf(type);
      accountValues.splice(index, 1);
    }
    
    setAccountTypes(accountValues);
  }

  const handleProductSelection = (product) => {
    let selectedValues = [...selectedProducts];
    if (!selectedValues.includes(product)) {
       selectedValues.push(product);
    } else {
      const index = selectedValues.indexOf(product);
      selectedValues.splice(index, 1);
    }
    
    setSelectedProducts(selectedValues);
  }

  return (
    <div className="page-wrapper">
      <div className="container">
        <div className='account-type'>
          
          <h1>Consulta aquí la información del tipo de cuenta que deseas abrir</h1>

          <div className='account-types-list'>

            <div className='account-types-entry-wrapper'>

              <button onClick={() => handleAccountTypeSelection(1)} className={accountTypes.includes(1) ? 'account-types-entry account-types-entry-active' : 'account-types-entry'}>
                {accountTypes.includes(1) &&
                <FontAwesomeIcon className='account-types-entry-icon' icon={faCircleCheck} />}

                <div className='account-types-heading'>
                  <h2>Cuenta Corriente</h2>
                  <div>
                    <img src={icnAccountType} alt="Cuenta Corriente" />
                  </div>
                </div>
                <p>Es una cuenta de depósito no remunerada que se ajusta a tus necesidades esenciales y mantiene tu dinero siempre a la vista.</p>
              </button>

              {/* Product Subcategories */}

              {accountTypes.includes(1) &&
              <div className='account-types-subcategories'>
                <ul>
                  {PRODUCTS_LIST_CUENTA_CORRIENTE.map((entry, i) =>
                    <ProductSubcateogryEntry
                      key={i}
                      entry={entry}
                      action={handleProductSelection}
                      isSelected={selectedProducts.includes(entry.value)}
                    />
                  )}
                </ul>
              </div>}

            </div>

            <div className='account-types-entry-wrapper'>

              <button onClick={() => handleAccountTypeSelection(2)} className={accountTypes.includes(2) ? 'account-types-entry account-types-entry-active' : 'account-types-entry'}>
                {accountTypes.includes(2) &&
                <FontAwesomeIcon className='account-types-entry-icon' icon={faCircleCheck} />}

                <div className='account-types-heading'>
                  <h2>Cuenta Ahorro</h2>
                  <div>
                    <img src={icnAccountType} alt="Cuenta Corriente" />
                  </div>
                </div>
                <p>Obtendrás intereses sobre saldo promedio mensual, sin tener que utilizar una libreta para disponer del dinero de forma inmediata.</p>
              </button>

              {/* Product Subcategories */}

              {accountTypes.includes(2) &&
              <div className='account-types-subcategories'>
                <ul>
                  {PRODUCTS_LIST_CUENTA_AHORRO.map((entry, i) =>
                    <ProductSubcateogryEntry
                      key={i}
                      entry={entry}
                      action={handleProductSelection}
                      isSelected={selectedProducts.includes(entry.value)}
                    />
                  )}
                </ul>
              </div>}

            </div>

            <div className='account-types-entry-wrapper'>

              <button onClick={() => handleAccountTypeSelection(3)} className={accountTypes.includes(3) ? 'account-types-entry account-types-entry-active' : 'account-types-entry'}>
                {accountTypes.includes(3) &&
                <FontAwesomeIcon className='account-types-entry-icon' icon={faCircleCheck} />}

                <div className='account-types-heading'>
                  <h2>Cuenta Moneda Extranjera</h2>
                  <div>
                    <img src={icnAccountType} alt="Cuenta Corriente" />
                  </div>
                </div>
                <p>Abre tu cuenta corriente no remunerada en Divisas, que te garantiza el manejo de tus fondos en moneda extranjera.</p>
              </button>

              {/* Product Subcategories */}

              {accountTypes.includes(3) &&
              <div className='account-types-subcategories'>
                <ul>
                  {PRODUCTS_LIST_CUENTA_MONEDA_EXTRANJERA.map((entry, i) =>
                    <ProductSubcateogryEntry
                      key={i}
                      entry={entry}
                      action={handleProductSelection}
                      isSelected={selectedProducts.includes(entry.value)}
                    />
                  )}
                </ul>
              </div>}

            </div>

          </div>

          <Button
              type="submit"
              // action={() => navigate('/onboarding')}
              action={() =>  window.location.href = "vu/onboarding/onboarding.html"}
              label={'Solicitar'}
              disabled={selectedProducts.length === 0}
          />
          
        </div>
      </div>
    </div>
  )
}

const ProductSubcateogryEntry = ({entry, action, isSelected}) => (
  <li>
    <button 
      className={isSelected ? 'account-types-subcategory-active' : ''}
      onClick={() => action(entry.value)}
    >
      <h3>{entry.label}</h3>
      <div>
        <img src={icnAccountType} alt={entry.label} />
      </div>
    </button>
  </li>
);