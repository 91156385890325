import { useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

// Services

// import EntryService from '../services/entry.service';

export default function CreateEntry() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState();
    const navigate = useNavigate();

    const createNewEntry = useCallback(async (values, userToken) => {
        setIsSubmitting(true);
        setSubmitError(null);
    
        try {
            // await EntryService.createEntry(values, userToken);

            setTimeout(() => {
                setIsSubmitting(false);

                navigate(`/success`, { state: { name: values.name } });
              }, 2000)
    
            // setIsSubmitting(false);

            // navigate(`/success`, { state: { name: values.name } });
        } catch(error) {
            setIsSubmitting(false);
            setSubmitError(error.response?.data?.message || error.message);
    
            window.scrollTo({ top: 0, behavior: "smooth"});
        }
    }, [navigate])

    return {
        isSubmitting,
        submitError,
        createNewEntry
    }
}
