import { 
  setFormValues, setShowModuleIntro, setModule, setFormStep, resetFormSteps,
 } from './forms.reducers';

  // Set Form Values ====================================================================================================

  export const handleSetFormValues = (values) => async (dispatch) => {
    dispatch(setFormValues(values));
  }

 // Set Show Module Intro ====================================================================================================

  export const handleSetShowModuleIntro = (showIntro) => async (dispatch) => {
    dispatch(setShowModuleIntro(showIntro));
  }

  // Set Form Module ====================================================================================================

  export const handleSetModule = (moduleNumber) => async (dispatch) => {
    dispatch(setModule(moduleNumber));
  }

  // Set Form Step ====================================================================================================

  export const handleSetFormStep = (formStep) => async (dispatch) => {
    dispatch(setFormStep(formStep));
  }

  // Reset Form Steps ====================================================================================================

  export const handleResetFormSteps = () => async (dispatch) => {
    dispatch(resetFormSteps());
  }