import React from 'react'
import VerificationInput from "react-verification-input";

import InputWrapper from './InputWrapper';

import './Styles.scss';

export default function CodeInput(props) {
    const {
        formik,
        fieldName,
    } = props;

    return (
        <InputWrapper {...props}>

            <div className='code-input-field'>

                <VerificationInput
                    validChars="0-9"
                    length={6}
                    placeholder=""
                    inputProps={{ type: "tel" }}
                    onChange={formik.handleChange(fieldName)}
                />

            </div>

        </InputWrapper>
    )
}

