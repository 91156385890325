import React from 'react'

// Styles

import './Styles.scss';

export default function FormInputGroup({children}) {
  return (
    <div className="form-input-group">

      {children}

    </div>
  )
}
