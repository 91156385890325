import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss'

export default function Button({label, action, type, disabled, isLoading, additionalStyles, secondaryStyle}) {
  return (
    <button className={secondaryStyle ? "btn btn-secondary" : "btn"}
      type={type ? type : null}
      onClick={() => action()}
      style={additionalStyles ? additionalStyles : {}}
      disabled={disabled || isLoading}
    >
        {!isLoading ? (
            label
        ) : (
            <FontAwesomeIcon icon={faSpinner} spin />
        )}
    </button>
  )
}
