import React from 'react'
import { InputText } from "primereact/inputtext";

import InputWrapper from './InputWrapper';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function TextInput(props) {
    const {
        formik,
        fieldName,
        isRequired,
        placeholder,
        isAlphabetic,
        disabled,
        maxLength
    } = props;

    return (
        <InputWrapper {...props}>

            <InputText
                id={fieldName}
                name={fieldName}
                value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                onChange={formik.handleChange(fieldName)}
                onBlur={formik.handleBlur(fieldName)}
                placeholder={placeholder}
                className={`input-field ${formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}`}
                required={isRequired}
                disabled={disabled}
                keyfilter={isAlphabetic ? /^[A-Za-z\s]*$/ : null}
                maxLength={maxLength}
            />

        </InputWrapper>
    )
}

