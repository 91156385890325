import React, {useState, useEffect} from 'react'

import { useSelector, useDispatch } from 'react-redux'

// Components

import SectionHeading from '../../../../UI/SectionHeading/SectionHeading';
import TextInput from '../../../../UI/FormInput/TextInput';
import DropDownInput from '../../../../UI/FormInput/DropDownInput';
import NumberInput from '../../../../UI/FormInput/NumberInput';
import FileInput from '../../../../UI/FormInput/FileInput';
import FormInputGroup from '../../../../UI/FormInputGroup/FormInputGroup';
import Button from '../../../../UI/Button/Button';
import LocaleTypeFields from '../LocaleTypeFields';
import ButtonWithIcon from '../../../../UI/ButtonWithIcon/ButtonWithIcon';

// Hooks

import GetStatesByCountry from '../../../../../hooks/GetStatesByCountry';
import GetCitiesByState from '../../../../../hooks/GetCitiesByState';
import GetMunicipalitiesByCity from '../../../../../hooks/GetMunicipalitiesByCity';
import GetParishesByMunicipality from '../../../../../hooks/GetParishesByMunicipality';
import GetSectorsByState from '../../../../../hooks/GetSectorsByState';

// Utilities

import { addItemToArray, removeItemFromArray, formatDropDownOptions } from '../../../../../utilities/helper-functions';

// Constants

import { initialValues } from '../InitialValues';

export default function KeepInTouch({formik, formStep, setFormStep}) {
    const { handleGetStatesByCountry } = GetStatesByCountry();
    const { handleGetCitiesByState } = GetCitiesByState();
    const { handleGetMunicipalitiesByCity } = GetMunicipalitiesByCity();
    const { handleGetParishesByMunicipality } = GetParishesByMunicipality();
    const { handleGetSectorsByState } = GetSectorsByState();
    // States
    const [getStatesIsLoading, setGetStatesIsLoading] = useState(false);
    const [getStatesError, setGetStatesError] = useState(null);
    const [states, setStates] = useState([]);
    // Cities
    const [getCitiesIsLoading, setGetCitiesIsLoading] = useState(false);
    const [getCitiesError, setGetCitiesError] = useState(null);
    const [cities, setCities] = useState([]);
    // Municipalities
    const [getMunicipalitiesIsLoading, setGetMunicipalitiesIsLoading] = useState(false);
    const [getMunicipalitiesError, setGetMunicipalitiesError] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    // Parishes
    const [getParishesIsLoading, setGetParishesIsLoading] = useState(false);
    const [getParishesError, setGetParishesError] = useState(null);
    const [parishes, setParishes] = useState([]);
    // Sectors
    const [getSectorsIsLoading, setGetSectorsIsLoading] = useState(false);
    const [getSectorsError, setGetSectorsError] = useState(null);
    const [sectors, setSectors] = useState([]);
    // Dropdown Options
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
    const dispatch = useDispatch();

    // Get the States by Country

    useEffect(() => {
        if (formik.values.country) {
            handleGetStatesByCountry(
                formik.values.country,
                setGetStatesIsLoading,
                setGetStatesError,
                setStates
            );
        }
    }, [handleGetStatesByCountry, formik.values.country])


    // Get the Cities by State

    useEffect(() => {
        if (formik.values.state) {
            handleGetCitiesByState(
                formik.values.state,
                setGetCitiesIsLoading,
                setGetCitiesError,
                setCities
            );
        }
    }, [handleGetCitiesByState, formik.values.state])


    // Get the Municipalities by City

    useEffect(() => {
        if (formik.values.city) {
            handleGetMunicipalitiesByCity(
                formik.values.city,
                setGetMunicipalitiesIsLoading,
                setGetMunicipalitiesError,
                setMunicipalities,
            );
        }
    }, [handleGetMunicipalitiesByCity, formik.values.city])


    // Get the Parishes by Municipality

    useEffect(() => {
        if (formik.values.municipalty) {
            handleGetParishesByMunicipality(
                formik.values.municipalty,
                setGetParishesIsLoading,
                setGetParishesError,
                setParishes
            );
        }
    }, [handleGetParishesByMunicipality, formik.values.municipalty])


    // Get the Sectors by State

    useEffect(() => {
        if (formik.values.state) {
            handleGetSectorsByState(
                formik.values.state,
                setGetSectorsIsLoading,
                setGetSectorsError,
                setSectors
            );
        }
    }, [handleGetSectorsByState, formik.values.state])

    return (
    <>
        <SectionHeading text={'Aunque ya no enviamos cartas, tu dirección de habitación es imprescindible para mantener el contacto'} />

        <FormInputGroup>

            <DropDownInput
                formik={formik}
                fieldName={'country'}
                label={'Pais'}
                options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'state'}
                label={'Estado'}
                options={formatDropDownOptions(states)}
                isRequired={true}
                disabled={states?.length === 0 || getStatesIsLoading || getStatesError}
                loading={getStatesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'city'}
                label={'Ciudad'}
                options={formatDropDownOptions(cities)}
                isRequired={true}
                disabled={cities?.length === 0 || getCitiesIsLoading || getCitiesError}
                loading={getCitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'municipalty'}
                label={'Municipio'}
                options={formatDropDownOptions(municipalities)}
                isRequired={true}
                disabled={municipalities?.length === 0 || getMunicipalitiesIsLoading || getMunicipalitiesError}
                loading={getMunicipalitiesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'church'}
                label={'Parroquia'}
                options={formatDropDownOptions(parishes)}
                isRequired={true}
                disabled={parishes?.length === 0 || getParishesIsLoading || getParishesError}
                loading={getParishesIsLoading}
            />

            <DropDownInput
                formik={formik}
                fieldName={'urban'}
                label={'Urbanización, Sector, Barrio'}
                options={formatDropDownOptions(sectors)}
                isRequired={true}
                disabled={sectors?.length === 0 || getSectorsIsLoading || getSectorsError}
                loading={getSectorsIsLoading}
            />

            <TextInput
                formik={formik}
                fieldName={'street'}
                label={'Avenida / Calle'}
                maxLength={80}
                isRequired={true}
            />

            <DropDownInput
                formik={formik}
                fieldName={'housecondition'}
                label={'Condición de la Vivienda'}
                options={formatDropDownOptions(dropDownOptions?.data?.housingConditions)}
                isRequired={true}
            />

            <NumberInput
                formik={formik}
                fieldName={'housetime'}
                label={'Tiempo de Domicilio (años)'}
                isRequired={true}
                max={120}
            />

            <DropDownInput
                formik={formik}
                fieldName={'inmueble'}
                label={'Tipo de Inmueble'}
                options={formatDropDownOptions(dropDownOptions?.data?.propertyTypes?.filter(o => o.name !== 'CTRO. COMERCIAL' && o.name !== 'LOCAL COMERCIAL'))}
                isRequired={true}
            />

        </FormInputGroup>

        {formik.values?.inmueble &&
        <FormInputGroup>

            <TextInput
                formik={formik}
                fieldName={'housename'}
                label={'Nombre / Identificación del Inmueble'}
                maxLength={80}
                isRequired={true}
            />

            <LocaleTypeFields formik={formik} />
        
        </FormInputGroup>}

        <FileInput
            formik={formik}
            fieldName={'rif'}
            label={'Registro Único de Información Fiscal (RIF), constancia de residencia o recibo de servicio.'}
            isRequired={true}
        />

        <SectionHeading text={'Redes sociales'} isSubHeading />

        {formik.values?.socialMediaLinks?.map((socialMediaLink, index) => (
        <div key={index}>
            <FormInputGroup>
                <DropDownInput
                    formik={formik}
                    fieldName={`socialMediaLinks.${index}.socialMedia`}
                    label={'Tipo de Dirección'}
                    options={formatDropDownOptions(dropDownOptions?.data?.addressTypes)}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`socialMediaLinks.${index}.socialMediaAddress`}
                    label={'Dirección'}
                    isRequired={true}
                />
            </FormInputGroup>
        </div>))}

        <>
        
            <ButtonWithIcon
                action={() => addItemToArray(formik, 'socialMediaLinks', initialValues.socialMediaLinks[0])}
                label={'Añadir Dirección'}
            />

            <ButtonWithIcon
                action={() => removeItemFromArray(formik, 'socialMediaLinks')}
                icon={'delete'}
                disabled={formik.values?.socialMediaLinks?.length === 1}
            />
        </>

        <div className='form-actions'>

            <Button
                action={() => dispatch(setFormStep(formStep - 1))}
                label={'Regresa'}
                // disabled={state?.isLoading}
                secondaryStyle={true}
            />

            <Button
                type="submit"
                action={formik.handleSubmit}
                label={'Continua'}
                // disabled={!(formik.isValid && formik.dirty)}
                // isLoading={state?.isLoading}
            />

        </div>
    </>
  )
}
