import * as yup from 'yup';

export const validationSchema = [
    yup.object().shape({
      text: yup
        .string(),
        // .required('Favor ingresar tu primer nombre'),
      mask: yup
        .string()
        // .required('Favor completar con tu número de cédula')
        // .min(11, 'Verifíca que estes ingresando el número correcto'),
    }),
    yup.object().shape({
      hasBankReference: yup
        .string()
        .required('Este campo es requerido'),
    }),
    yup.object().shape({
      field2: yup
        .string()
        // .required('Favor selecionar el tipo de vivienda'),
    }),
    yup.object().shape({
      field3: yup
        .string()
        // .required('Favor selecionar el tipo de vivienda'),
    })
];