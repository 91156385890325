import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    // Log In

    setLoginLoading: (state) => {
      state.user = {
        isLoading: true,
      };
    },
    setLoginSuccess: (state, action) => {
      state.user = {
        isLoggedIn: true,
        data: action.payload
      };
    },
    setLoginFailure: (state, action) => {
      state.user = {
        error: action.payload,
      }
    },

    // Sign out

    signOut: (state, action) => {
      state.user = action.payload ? { error: action.payload } : {};
      state.userPermissions = {}
    },

  },
})

export const {
  setLoginLoading, setLoginSuccess, setLoginFailure, signOut,
} = authSlice.actions

export default authSlice.reducer;