// Form initial values

export const initialValues = {
    check: [],
    name: '',
    firstlastname: '',
    socialMediaLinks: [
        {
            socialMedia:'',
            socialMediaAddress: '',
        }
    ]
};