import { useCallback } from 'react';

// Services

import OptionsService from '../services/options.service';

export default function GetCitiesByState() {

    // Get the Cities by State

    const handleGetCitiesByState = useCallback(async (state, setLoading, setError, setCities) => {
        setLoading(true);
        setError(null);

        try {
            const citiesRequest = await OptionsService.getCitiesByState(state);

            setLoading(false);
            setCities(citiesRequest?.data?.data);

        } catch(error) {

            setLoading(false);
            setError(error.response?.data?.message || error.message);

        }
    }, []);

    return {
        handleGetCitiesByState,
    }
}
