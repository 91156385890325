import React from 'react';

// Components

import TextInput from '../../../UI/FormInput/TextInput';
import DropDownInput from '../../../UI/FormInput/DropDownInput';
import MaskInput from '../../../UI/FormInput/MaskInput';

// Utilities

import { formatDropDownOptions } from '../../../../utilities/helper-functions';

export default function JobLocaleTypeFields({formik, index, dropDownOptions}) {
  return (
    <>
        {formik.values?.jobReferences[index].inmueble === '5' ? (
        <>
            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.housetower`}
                label={'Torre'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'housefloor'}
                label={'Piso'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.houseapartment`}
                label={'Apartamento'}
                maxLength={80}
                isRequired={true}
            />
        </>
        ) : formik.values?.jobReferences[index].inmueble === '4' ? (
        <>
            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.housebuilding`}
                label={'Edificio'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={'housetower'}
                label={'Torre'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.housefloor`}
                label={'Piso'}
                maxLength={80}
                isRequired={true}
            />
            
            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.houseoffice`}
                label={'Oficina'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.houselocale`}
                label={'Local'}
                maxLength={80}
                isRequired={true}
            />
        </>      
        ) : (
        <>
            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.housebuilding`}
                label={'Edificio'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.housetower`}
                label={'Torre'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
              formik={formik}
              fieldName={`jobReferences.${index}.housefloor`}
              label={'Piso'}
              maxLength={80}
              isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.houseoffice`}
                label={'Oficina'}
                maxLength={80}
                isRequired={true}
            />

            <TextInput
                formik={formik}
                fieldName={`jobReferences.${index}.houselocale`}
                label={'Local'}
                maxLength={80}
                isRequired={true}
            />

            {formik.values?.jobReferences[index].country === '0' &&
            <>
                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.houseInternationalDir`}
                    label={'Dirección Internacional (Domicilio / Empleo)'}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.housecountry`}
                    label={'País'}
                    options={formatDropDownOptions(dropDownOptions?.data?.countries)}
                    isRequired={true}
                />

                <DropDownInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.housecity`}
                    label={'Ciudad'}
                    options={formatDropDownOptions(dropDownOptions?.data?.cities)}
                    isRequired={true}
                />

                <TextInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.houseDirField`}
                    label={'Campo abierto para el resto de la dirección'}
                    isRequired={true}
                />

                <MaskInput
                    formik={formik}
                    fieldName={`jobReferences.${index}.houseInternationalPhone`}
                    label={'Teléfono Internacional'}
                    mask={'999-999-9999'}
                    isRequired={true}
                />
            </>}
        </>
        )}
    </>
  )
}
