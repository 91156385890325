import React from 'react'

// Module Steps

import JobInformation from './Steps/1.JobInformation';

export default function JobInfo({formik, formStep, goToPreviousModule}) {
  return (
    <>
      {/* STEP 1: Job Information */}

      {formStep === 0 &&
      <JobInformation
        formik={formik}
        goToPreviousModule={goToPreviousModule}
      />}
    </>
  )
}
